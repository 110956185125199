import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CanLoadPermissions } from '@core/can-load-permissions/can-load-permissions';

const routes: Routes = [
  {
    path: 'monitoring',
    loadChildren: './monitoring/monitoring.module#MonitoringModule',
    canLoad: [CanLoadPermissions]
  },
  {
    path: 'virtualLobby',
    loadChildren: './virtual-lobby/virtual-lobby.module#VirtualLobbyModule',
    canLoad: [CanLoadPermissions]
  },
  {
    path: 'eventsMonitoring',
    loadChildren:
      './events-monitoring/events-monitoring.module#EventsMonitoringModule',
    canLoad: [CanLoadPermissions]
  },
  {
    path: 'accessCallLog',
    loadChildren:
      './access-call-log/access-call-log.module#AccessCallLogModule',
    canLoad: [CanLoadPermissions]
  },
  {
    path: 'deviceSearch',
    loadChildren: './device-search/device-search.module#DeviceSearchModule',
    canLoad: [CanLoadPermissions]
  },
  {
    path: 'pageNotFound',
    loadChildren: './page-not-found/page-not-found.module#PageNotFoundModule'
  },
  {
    path: 'pageForbidden',
    loadChildren: './page-forbidden/page-forbidden.module#PageForbiddenModule'
  },
  {
    path: 'pageTimeout',
    loadChildren: './page-timeout/page-timeout.module#PageTimeoutModule'
  },
  {
    path: '',
    redirectTo: '/monitoring',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/pageNotFound'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
