import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccessCallService } from '@core/access-call/access-call.service';
import { AccessCall } from '@shared/models/access-call.model';
import { StorageService } from '@core/storage/storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-access-call-messages',
  templateUrl: './access-call-messages.component.html',
  styleUrls: ['./access-call-messages.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AccessCallMessagesComponent implements OnInit, AfterViewInit, OnDestroy {
  private static readonly MAX_MSG_SIZE = 128;
  @ViewChild('accessCallMessages', { static: false }) public accessCallMessagesElRef: ElementRef;
  @Input() public accessCall: AccessCall;
  public messages: { content; status: 'error' | 'success' }[] = [];
  public msgContent = '';
  public messageCharsLeft: string;
  private currentMessaeCharsLeft = AccessCallMessagesComponent.MAX_MSG_SIZE;

  private ngUnsubscribe = new Subject();

  constructor(
    private translateService: TranslateService,
    private accessCallService: AccessCallService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.messages = this.storageService.getAccessCallMessages() || [];
    this.updateRemainingChars();
  }

  ngOnDestroy() {
    if (this.ngUnsubscribe) {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }
  }

  updateRemainingChars() {
    this.translateService
      .get('ACCESS_CALL_MESSAGE_CHARS_LEFT', {
        remainingChars: '$remainingCharsPlaceholder'
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((msg: string) => {
        this.currentMessaeCharsLeft =
          AccessCallMessagesComponent.MAX_MSG_SIZE - this.msgContent.length;
        this.messageCharsLeft = msg.replace(
          '$remainingCharsPlaceholder',
          this.currentMessaeCharsLeft + ''
        );
      });
  }

  ngAfterViewInit() {
    this.scrollMessagesContainerToBottom();
  }

  private scrollMessagesContainerToBottom() {
    if (this.accessCallMessagesElRef) {
      setTimeout(() => {
        const accessCallMessagesEl = this.accessCallMessagesElRef
          .nativeElement as HTMLElement;
        accessCallMessagesEl.scrollTop = accessCallMessagesEl.scrollHeight;
      });
    }
  }

  public sendMsg(msg?: string) {
    const hasMsg = msg && msg.trim().length;
    const hasThisMsg = this.msgContent && this.msgContent.trim().length;
    if (!hasMsg && !hasThisMsg) {
      return;
    }

    const message = msg || this.msgContent;

    this.accessCallService
      .sendDisplayMsg(this.accessCall.device.id, message)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => this.doSendDisplayMsg(message, 'success'),
        () => this.doSendDisplayMsg(message, 'error')
      );
  }

  private doSendDisplayMsg(message, status) {
    this.messages.push({ content: message, status: status });
    this.storageService.setAccessCallMessages(this.messages);
    this.scrollMessagesContainerToBottom();
    this.msgContent = '';
  }
}
