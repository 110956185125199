import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';

@Injectable()
export class CardReaderService {
  private endpoints = {
    readCardNumber: `${environment.samReaderUrl}/readers/smartcard/read`
  };

  constructor(public http: HttpClient) { }

  public read(): Observable<any> {
    return this.http.get<any>(this.endpoints.readCardNumber);
  }
}
