import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { Preferences } from '@shared/models/preferences.model';
import { PermissionRoutine } from '@shared/models/permission-routine.model';
import { DocumentType } from '@shared/models/document-type.model';
import { Tenant } from '@shared/models/tenant.model';
import { UserData } from '@shared/models/user-data.model';
import { VirtualLobby } from '@shared/models/virtual-lobby.model';

enum COOKIE_KEY {
  G5_USER = 'G5User'
}

@Injectable()
export class StorageService {
  /* tslint:disable:member-ordering */
  private readonly appStorageNamespace = key => `br.com.senior.sam.virtuallobby.${key}`;
  private readonly tourKey = this.appStorageNamespace('tour');
  private readonly tenantKey = this.appStorageNamespace('tenant');
  private readonly preferencesKey = this.appStorageNamespace('preferences');
  private readonly permissionsKey = this.appStorageNamespace('permissions');
  private readonly documentTypesKey = this.appStorageNamespace(
    'document-types'
  );
  private readonly accessCallMessagesKey = this.appStorageNamespace(
    'document-types'
  );
  private readonly userDataKey = this.appStorageNamespace('userData');
  private readonly virtualLobbiesKey = this.appStorageNamespace('virtualLobbies');
  private readonly selectedVirtualLobbyKey = this.appStorageNamespace('selectedVirtualLobby');
  private readonly selectedVirtualLobbyActionsKey = this.appStorageNamespace('selectedVirtualLobbyActions');
  private readonly alertedNotificationsKey = this.appStorageNamespace('alertedNotifications');
  private readonly firstTour = this.appStorageNamespace('firstTour');

  constructor(private cookieService: CookieService) { }

  public isFirstTour() {
    const isFirstTour = sessionStorage.getItem(this.firstTour);
    if (!isFirstTour) {
      sessionStorage.setItem(this.firstTour, 'true');
    }
    return !isFirstTour;
  }

  public setTenant(tenant: Tenant) {
    sessionStorage.setItem(this.tenantKey, JSON.stringify(tenant));
  }

  public getTenant(): Tenant {
    return <Tenant>JSON.parse(sessionStorage.getItem(this.tenantKey));
  }

  public setPreferences(preferences: Preferences) {
    sessionStorage.setItem(this.preferencesKey, JSON.stringify(preferences));
  }

  public setPermissions(permissions: PermissionRoutine[]) {
    sessionStorage.setItem(this.permissionsKey, JSON.stringify(permissions));
  }

  public setAllDocumentTypes(documentTypes: any[]) {
    sessionStorage.setItem(
      this.documentTypesKey,
      JSON.stringify(documentTypes)
    );
  }

  public setTourStateActive(active: boolean) {
    sessionStorage.setItem(this.tourKey, JSON.stringify(active));
  }

  public setUserData(userData: UserData) {
    localStorage.setItem(this.userDataKey, JSON.stringify(userData));
  }

  public getUserData(): UserData {
    const userData = localStorage.getItem(this.userDataKey);
    return userData ? JSON.parse(userData) : null;
  }

  public hasG5UserCookie() {
    return this.cookieService.get(COOKIE_KEY.G5_USER);
  }

  public setAccessCallMessages(messages: any[]) {
    localStorage.setItem(this.accessCallMessagesKey, JSON.stringify(messages));
  }

  public getAccessCallMessages(): any[] {
    return JSON.parse(localStorage.getItem(this.accessCallMessagesKey));
  }

  public getTourStateActive() {
    return sessionStorage.getItem(this.tourKey);
  }

  public removeTourStateActive() {
    sessionStorage.removeItem(this.tourKey);
  }

  public compareToStepInStorage(tourId, step?): boolean {
    const hopscotchState = sessionStorage.getItem('hopscotch.tour.state');
    if (step) {
      return hopscotchState ? hopscotchState === tourId + ':' + step : false;
    } else {
      return hopscotchState ? hopscotchState.indexOf(tourId) !== -1 : false;
    }
  }

  public getPreferences(): Preferences {
    return <Preferences>JSON.parse(sessionStorage.getItem(this.preferencesKey));
  }

  public getUserPermissions(): PermissionRoutine[] {
    return <PermissionRoutine[]>JSON.parse(
      sessionStorage.getItem(this.permissionsKey)
    );
  }

  public getAllDocumentTypes(): DocumentType[] {
    if (this.documentTypesKey) {
      return <DocumentType[]>JSON.parse(
        sessionStorage.getItem(this.documentTypesKey)
      );
    }
  }

  public setVirtualLobbies(virtualLobbies: VirtualLobby[]) {
    localStorage.setItem(this.virtualLobbiesKey, JSON.stringify(virtualLobbies));
  }

  public getVirtualLobbies(): VirtualLobby[] {
    const virtualLobbies = localStorage.getItem(this.virtualLobbiesKey);
    return virtualLobbies ? JSON.parse(virtualLobbies) : null;
  }

  public setSelectedVirtualLobby(virtualLobby: VirtualLobby) {
    localStorage.setItem(this.selectedVirtualLobbyKey, JSON.stringify(virtualLobby));
  }

  public getSelectedVirtualLobby(): VirtualLobby {
    const virtualLobby = localStorage.getItem(this.selectedVirtualLobbyKey);
    return virtualLobby ? JSON.parse(virtualLobby) : null;
  }

  public setSelectedVirtualLobbyActions(actions: any) {
    localStorage.setItem(this.selectedVirtualLobbyActionsKey, JSON.stringify(actions));
  }

  public getSelectedVirtualLobbyActions(): any {
    const actions = localStorage.getItem(this.selectedVirtualLobbyActionsKey);
    return actions ? JSON.parse(actions) : null;
  }

  public setAlertedNotifications(incident: any) {
    localStorage.setItem(this.alertedNotificationsKey, JSON.stringify(incident));
  }

  public getAlertedNotifications(): any {
    const alertedNotifications = localStorage.getItem(this.alertedNotificationsKey);
    return alertedNotifications ? JSON.parse(alertedNotifications) : null;
  }
}
