export class DocumentType {
  constructor(
    public id: number,
    public name: string,
    public label: string,
    public mask: string,
    public required: boolean,
    public documentToREP: boolean
  ) { }

  static nullInstance() {
    return new DocumentType(-1, '', '', '', false, false);
  }
}
