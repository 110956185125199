export const translateEs = {
  DOC_URL_ACTIONS: 'https://documentacao.senior.com.br/seniorxplatform/manual-do-usuario/ronda/#security-hub/monitoramento.htm',
  DOC_URL_MONITORING: 'https://documentacao.senior.com.br/seniorxplatform/manual-do-usuario/ronda/#security-hub/monitoramento.htm',
  DOC_URL_VIRTUAL_LOBBY: 'https://documentacao.senior.com.br/seniorxplatform/manual-do-usuario/ronda/#security-hub/monitoramento.htm',
  PHYSICAL_LOCATIONS: 'Lugares físicos',
  OPERATION: 'Operación',
  FLOOR_PLAN: 'Planta baja',
  CAMERAS: 'Cámaras',
  INFORMATION_RELOAD_IN: 'Las informaciones serán actualizadas en',
  RELOAD_NOW: 'Recargar ahora',
  PEOPLE_PRESENT: 'Personas presentes',
  IRREGULAR_PRESENCES: 'Presencias irregulares',
  IRREGULAR_PRESENCE_BY_WORK_SCHEDULE: 'Personas con presencia irregular (motivo: escala)',
  NO_IRREGULAR_PRESENCE_AT_THE_LOCATION: 'No hay presencia irregular en ese local',
  WEB_MONITORING: 'Monitoreo Web',
  DATE_OF_ACCESS: 'Fecha de acceso',
  PHONE_NUMBER: 'Teléfono',
  LOCATION: 'Lugar',
  DOES_NOT_HAVE_ANY_CHILD_PHYSICAL_LOCATIONS: '{{physicalLocation}} no posee ningún lugar hijo.',
  CHILD_PHYSICAL_LOCATIONS: 'Lugares hijo',
  OCCURRENCES: 'Ocurrencias',
  PRIORITY_PLANNED: 'Planeada',
  PRIORITY_LOW: 'Prioridad baja',
  PRIORITY_MEDIUM: 'Prioridad media',
  PRIORITY_HIGH: 'Prioridad alta',
  PRIORITY_CRITICAL: 'Crítica',
  PENDING: 'Pendiente',
  IN_PROGRESS: 'En progreso',
  STOPPED: 'En pausa',
  DONE: 'Concluido',
  NO_PERSON_PRESENT_AT_THE_LOCATION: 'No hay personas presentes en este local',
  LAST_ACCESSES: 'Últimos accesos',
  DOES_NOT_HAVE_ANY_ACCESS: '{{physicalLocation}} no posee accessos registrados.',
  UNKNOWN_ACCESS_VALID: 'Acceso permitido',
  UNKNOWN_ACCESS_DENIED: 'Acceso negado',
  ENTRANCE_ACCESS_VALID: 'Entrada permitida',
  ENTRANCE_ACCESS_DENIED: 'Entrada negada',
  EXIT_ACCESS_VALID: 'Salida permitida',
  EXIT_ACCESS_DENIED: 'Salida negada',
  DEVICES: 'Dispositivos',
  DOES_NOT_HAVE_ANY_DEVICE: '{{physicalLocation}} no posee dispositivos.',
  DOES_NOT_HAVE_ANY_OCCURRENCES: '{{physicalLocation}} no posee ocurrencias registrados',
  EVENTS: 'Eventos',
  DEVICE_STARTED: 'Dispositivo inicializado',
  DEVICE_ONLINE: 'Dispositivo online',
  DEVICE_OFFLINE: 'Dispositivo offline',
  OFFLINE_MODULE: 'Módulo offline',
  ISSUANCE_OF_TIME_AND_ATTENDANCE_REPORT: 'Reporte de marcación',
  STARTED_USING_USB_FISCAL_PORT: 'Usando puerta fiscal',
  PAPER_REEL_REPLACED: 'Bobina de papel cambiada',
  LACK_OF_PAPER: 'Sin papel',
  NO_PAPER: 'Sin papel',
  LOW_MEMORY: 'Poca memoria',
  NO_MEMORY: 'Memoria',
  BATTERY_REPLACED: 'Batería trocada',
  STARTED_USING_BATTERY: 'Utilizando batería',
  STARTED_USING_ENERGY: 'Utilizando energía',
  DEVICE_LOCKED: 'Dispositivo bloqueado',
  DEVICE_UNLOCKED: 'Dispositivo desbloqueado',
  TAMPER: 'Dispositivo adulterado (Tamper)',
  DOOR_HELD_OPEN: 'Puerta abierta',
  DOOR_FORCED_OPEN: 'Intrusión',
  MEMORY: 'Memoria',
  BATTERY: 'Batería',
  PAPER: 'Papel',
  ALARMED: 'Alarmado',
  DEFAULT_STATE: 'Estado estándar',
  ONLINE: 'Online',
  OFFLINE: 'Offline',
  DOES_NOT_HAVE_ANY_EVENTS: '{{physicalLocation}} no posee eventos registrados.',
  UNLOCKED: 'Desbloqueado',
  LOCKED: 'Bloqueado',
  POWER: 'Red',
  HAS_PAPER: 'Tem papel',
  AVALIABLE_MEMORY: 'Disponible',
  EXIT: 'Salida',
  ENTRANCE: 'Entrada',
  UNKNOWN: 'Desconocida',
  ACCESS_VALID: 'Acceso permitido',
  ACCESS_DENIED_PERMISSION: 'Lugar no permitido',
  ACCESS_DENIED_PERSON_NOT_ASSOCIATED_WITH_VEHICLE: 'Acceso denegado por persona no asociada con vehículo',
  ACCESS_DENIED_SITUATION: 'Acceso denegado por situación',
  ACCESS_DENIED_CARD_VALIDITY: 'Validez de la credencial',
  ACCESS_DENIED_LEVEL_CONTROLER: 'Controle de nivel',
  ACCESS_DENIED_CREDIT_ACCESS: 'Pela falta de crédito de acceso',
  ACCESS_DENIED_PERSON_RANGE: 'Por el rango horario de la persona',
  ACCESS_DENIED_LOCAL_RANGE: 'Por el rango horario del lugar',
  ACCESS_DENIED_CARD_NOT_FOUND: 'Tarjeta de identificación no encontrada',
  ACCESS_VALID_ACCOMPANY: 'Acompañado por el autorizador',
  ACCESS_DENIED_ACCOMPANY: 'Acompañado por el autorizador',
  ACCESS_DENIED_INVALID_AUTHORIZER: 'Autorizador incorrecto',
  ACCESS_DENIED_WAITING_FOR_NEXT_VALIDATION: 'Aguardando por la próxima validación',
  ACCESS_DENIED_ANTI_PASSBACK: 'Bloqueado por anti-dupla',
  ACCESS_DENIED_CREDIT_RANGE: 'Fuera del rango de crédito de acceso',
  ACCESS_DENIED_STOCKING_CONTROL: 'Capacidad alcanzada',
  ACCESS_DENIED_NOT_PARKING_SPACE_TYPE: 'Tipo de vagante indisponible',
  ACCESS_DENIED_NOT_PARKING_SPACE: 'No hay vacante',
  ACCESS_COERCION: 'Acceso sob coacción',
  ACCESS_DENIED_BIOMETRY: 'Por biometría',
  ACCESS_DENIED_CARD_FORMAT_ERROR: 'Formato de tarjeta de identificación inválido',
  ACCESS_DENIED_FACILITY_CODE: 'Por el facility code',
  ACCESS_DENIED_PASSWORD: 'Por la contraseña',
  ACCESS_DENIED_SECOND_CARD_NOT_PRESENTED: 'Segunda tarjeta de identificación no presentada',
  ACCESS_DESISTENCE: 'Desistencia de acceso',
  ACCESS_VALID_AUTHORIZER: 'Por el autorizador',
  ACCESS_VALID_FACILITY_CODE: 'Por el facility code',
  ACCESS_VALID_FRAUD: 'Burla',
  ACCESS_VALID_OUT_REPOSE: 'Fuera de reposo',
  ACCESS_DENIED: 'Acceso negado',
  ACCESS_DENIED_CREDENTIAL_NOT_FOUND: 'Por credencial no encontrada',
  ACCESS_DENIED_CREDENTIAL_VALIDITY: 'Por validad de credencial',
  MONITOR: 'Monitor',
  PERSON: 'Persona',
  DIRECTION: 'Dirección',
  PHYSICAL_LOCATION: 'Lugar físico',
  DEVICE: 'Dispositivo',
  ACCESS_TYPE: 'Tipo de acceso',
  ACCESS_MONITORING: 'Monitoramiento de acceso',
  ACCESS_DATE_TIME: 'Fecha/hora del acceso',
  NAME: 'Nombre',
  DEVICE_STATUS: 'Estado del dispositivo',
  ADDRESS: 'Dirección',
  PAPER_STATUS: 'Estado del papel',
  MEMORY_STATUS: 'Estado de la memória',
  POWER_SUPPLY: 'Fuente de energía',
  SITUATION: 'Situación',
  NO_REGISTERED_DEVICES: 'No existen dispositivos registrados',
  DEVICE_SEARCH: 'Consulta de dispositivos',
  NEW_CREDENTIAL: 'Nueva credencial',
  REGISTER_ENTRANCE: 'Registro de entrada',
  REGISTER_CHECKOUT: 'Registro de salida',
  MOVEMENTS: 'Movimiento',
  DETAILS: 'Detalles',
  OPEN_ENTRANCES: 'Entradas en abierto',
  ASSOCIATED_PEOPLE: 'Personas asociadas',
  INFORMATION: 'Informaciones',
  ACTIONS: 'Acciones',
  IDENTIFICATION: 'Identificación',
  PERSON_DETAILS: 'Detalles de la persona',
  REGISTER_ENTRY: 'Registro de entrada',
  SELECT_A_DOCUMENT_TYPE: '< Seleccione un tipo',
  NEXT_STEP: 'Próximo',
  TYPE_TO_SEARCH: 'Escriba para buscar',
  DOCUMENT: 'Documento',
  EMAIL_ADDRESS: 'Dirección de e-mail',
  BACK: 'Volver',
  ROLE: 'Papel',
  EXPIRATION_DATE: 'Fecha de validad',
  VISITED_PERSON: 'Persona visitada',
  SAVE: 'Guardada',
  SEARCH_VISITED_PERSON: 'Búsqueda persona visitada',
  DOCUMENT_TYPE: 'Tipo de documento',
  MANDATORY_FIELD: 'Campo obligatorio',
  SAVED_SUCCESSFULLY: 'Guardado con éxito',
  CHECKOUT: 'Registro de salida',
  SELECT_PERSON: 'Seleccione una persona',
  REGISTER_LEAVE: 'Registrar salida',
  CANCEL: 'Cancelar',
  NAME_OR_DOCUMENT: 'Nombre o documento',
  THE_ADDRESS_MIGHT_BE_INCORRECT_NOT_AVAILABLE_ANYMORE_THE_CONTENT_MIGHT_HAVE_BEEN_REMOVED: 'La dirección puede estar incorrecta, no está disponible o el contenido pudo haber sido removido.',
  PAGE_NOT_FOUND: 'Página n​o encontrada',
  PAGE_ERROR_ACCESS_DENIED: 'Acceso negado',
  YOU_NOT_HAVE_PERMISSIONS_TO_ACCESS_THIS_CONTENT: 'Usted no posee permiso para acceder ese contenido.',
  OOPS: '¡Ups!',
  AN_UNEXPECTED_ERROR_OCCURRED_ON_THE_SERVER: 'Ocurrió un error inesperado en el servidor',
  THIS_MIGHT_HAVE_OCCURRED_DUE_TO_MAINTENANCE_ON_OUR_SERVERS: 'Esto puede haber ocurrido debido a alguna mantención en nuestros servidores o alguna falla durante la navegación.',
  NORMAL: 'Normal',
  SELECT_CAMERA: 'Seleccione la cámara',
  CAMERA_NOT_FOUND: 'Cámara no encontrada',
  ERROR_EXECUTING_LIVE_STREAMING: 'No fue posible obtener la imagen del servidor',
  NO_CCTV_CAMERAS: 'No es posible buscar historial de imágenes de cámaras que no poseen servidor',
  HIDE_CAMERA: 'Ocultar cámara',
  UNAVAIBLE_SERVICE: 'Servicio indisponible',
  PROXY_SERVER_NOT_FOUND: 'Servidor proxy no encontrado',
  NO_CAMERAS_ASSOCIATED_TO_LOCATION: 'No hay cámaras asociadas a este local',
  SELECTED_FILTERS: 'Filtros seleccionados',
  ALL: 'Todos',
  GROUP: 'Grupo',
  FILTERS: 'Filtros',
  NO_ACCESS_REGISTERED: 'Ningún acceso registrado',
  CLEAR: 'Limpiar',
  SEARCH: 'Búsqueda',
  SELECT_FILTERS: 'Seleccionar filtros',
  CLOSE_NOTIFICATIONS: 'Cerrar notificaciones',
  VIEW_MORE: 'Ver más',
  NO_NOTIFICATIONS: 'No hay notificaciones',
  NO_AVAILABLE_ACTIVE_LOBBY: 'No fue encontrada ninguna portería activa disponible para su usuario',
  CONTACT_SYSTEM_ADMINISTRATOR: 'En caso de dudas entre en contacto con el administrador de su sistema',
  WEB_MONITOR: 'Monitor Web',
  VIRTUAL_LOBBY: 'Portería Virtual',
  HELP: 'Ayuda',
  NOTIFICATIONS: 'Notificaciones',
  LOGOFF: 'Cerrar sesión',
  NO_INFORMATION_LOBBY: 'No hay informaciones adicionales disponibles para esta portería',
  NO_ACTIONS_REGISTERED: 'No hay acciones registradas',
  FIND_MORE_HERE: 'Sepa más aquí',
  THIS_VIRTUAL_LOBBY_HAS_NO_OPEN_ENTRIES: 'Esa portería no posee entradas en abierto',
  REGISTERED_LEAVE_SUCCESSFUL_FOR_PERSON: 'Registro de salida hecho con éxito para {person}',
  THERE_ARE_NO_MEMBERS_ASSOCIATED_WITH_THIS_ENTRY: 'Esa portería no posee personas asociadas',
  OPEN_ENTRIES: 'Entradas en abierto',
  ERROR_VALIDATING_USER: 'No fue posible obtener la imagen del servidor',
  ADD_CAMERA_TO_CONTAINER: 'Agregue una cámara',
  ACTIVATION_EXECUTED_SUCCESSFULLY: 'Accionamiento realizado con éxito',
  ROLE_FOR_PERSON_ROLE_OVERLAP: 'La persona ya posee este papel',
  SAVE_FAILED: 'No fue posible salvar',
  CREDENTIAL_NUMBER: 'Numero de la credencial',
  IDENTITY_DOCUMENT: 'Documento',
  SELECT_CREDENTIAL_TYPE: '< Seleccione un tipo',
  ISSUE_CREDENTIAL: 'Entregar credencial',
  ISSUE_CREDENTIAL_BUTTON: 'Entregar',
  PERSON_ROLE_NOT_ACTIVE_PERSON: 'No es posible conceder acceso para una persona bloqueada, para mas informaciones contactar un administrador.',
  EXPIRATION_DATE_CANNOT_BE_LATER_THAN_CURRENT_DATE: 'Fecha de validad debe ser mayor que la fecha actual',
  CANNOT_GRANT_ACCESS_TO_BLOCKED_PERSON_FOR_MORE_INFORMATION_CONTACT_YOUR_ADMINISTRATOR: 'No es posible conceder acceso para una persona bloqueada, para mas informaciones contactar un administrador.',
  CANNOT_GRANT_ACCESS_TO_BLOCKED_PERSON_FOR_MORE_INFORMATION_ACCESS_THE_PERSON_REGISTRATION: 'No es posible conceder acceso para una persona bloqueada, para mas informaciones acceder el registro de persona.',
  ACCESS: 'Acceder',
  CREDENTIAL_EMITTED_SUCCESSFUL_FOR_PERSON: 'Credencial emitida con éxito para {person}',
  CREDENTIAL_NUMBER_ALREADY_IN_USE: 'Ese número de tarjeta de identificación ya está siendo utilizado por otra persona',
  VISITED_AND_VISITOR_SAME_PERSON: 'Visitante y visitado no pueden ser la misma persona',
  CREDENTIAL_LIMIT_EXCEEDED: 'Excedió el limite disponible de credenciales activas en el sistema. No fue posible inserir una nueva credencial',
  CANNOT_SELECT_SAME_TECHNOLOGY: 'No es posible repetir la tecnología de la tarjeta de identificación',
  CREDENTIAL_EXPIRED: 'La credencial está expirada',
  OPERATION_ERROR: 'No fue posible ejecutar la operación',
  MUST_HAVE_A_MANDATORY_DOCUMENT: 'Persona debe poseer algún documento obligatorio',
  FINAL_STEP: 'Terminar',
  BARCODE: 'Barras',
  PROXIMITY: 'Proximidad',
  SMARTCARD: 'Smart Card',
  ROLE_EXPIRATION: 'Validad del papel',
  DOCUMENT_ALREADY_IN_USE: 'El documento no puede ser agregado porque ya existe',
  PERSON_ALREADY_HAS_THIS_ROLE: 'La persona ya posee este papel',
  REGISTER_ENTRY_BUTTON: 'Registrar entrada',
  DOES_NOT_HAVE_FLOOR_PLAN: '{{physicalLocation}} no posee planta baja',
  SELECT_AN_IMAGE_TO_BEGIN_THE_MAP: 'Seleccione una imagen para comenzar el mapa',
  IT_WILL_BE_USED_WITH_THE_11_ASPECT_RATIO: 'Ella será usada en aspecto 1:1',
  ERROR_WHILE_LOAD_IMAGE: 'Error al cargar imagen',
  ONLY_IMAGES_IN_JPEG_AND_PNG_FORMAT_ARE_ALLOWED: 'Solo imágenes en el formato PNG y JPEG son aceptadas',
  CONFIRMATION: 'Confirmación',
  YES: 'Si',
  NO: 'No',
  ALREADY_HAS_ACCESS_CREDENTIAL: 'Esa persona ya posee una credencial del mismo formato en abierto.',
  TERMINATE_ACCESS_CREDENTIAL: '¿Desea finalizar la credencial?',
  ID_ALREADY_BEING_USED: 'Ese número de tarjeta de identificación ya está siendo utilizado por {{personNameAndDocument}}',
  ROLE_WHITOUT_PHYSICAL_LOCATION_RANGE: 'Su papel no posee alcance para ver las informaciones de ese lugar.',
  CLEAR_FORM: 'Limpiar',
  TOUR_MOVEMENTS_INTRODUCTION: 'En el menú Movimiento, es posible entregar una nueva credencial y registrar entradas y salidas.',
  TOUR_REGISTRY_ENTRY_INTRODUCTION: 'Ahora, usted va hacer el Registro de entrada.',
  TOUR_REGISTRY_ENTRY_STEP_1: 'En el paso 1, seleccione un tipo de documento, informe su valor y el nombre de la persona, haciendo clic en Próximo enseguida.',
  TOUR_REGISTRY_ENTRY_STEP_2: 'En el paso 2, usted puede incluir los detalles de la persona, o ir directo para el Próximo paso.',
  TOUR_REGISTRY_ENTRY_STEP_3: 'En el paso 3, informe el papel y su validad y opcionalmente, la persona visitada.',
  SKIP: 'Saltar',
  MASTER_CONTROLLERS: 'Gerenciadores',
  MASTER_CONTROLLER: 'Gerenciador',
  MODULES: 'Módulos',
  MODULE: 'Módulo',
  CHILD_LOCATIONS: 'Lugares hijos',
  CHILD_LOCATION: 'Lugar hijo',
  INPUTS: 'Entradas',
  INPUT: 'Entrada',
  CAMERA: 'Cámara',
  NO_CHILD_LOCATIONS_AVALIABLE: 'No existen lugares hijos dispoibles',
  SELECT_A_CHILD_LOCATION: 'Seleccione un lugar hijo',
  REMOVE: 'Remover',
  SELECT: 'Seleccionar',
  SELECT_A_CAMERA: 'Seleccione una cámara',
  NO_CAMERAS_AVALIABLE: 'No existen cámaras disponibles',
  SELECT_A_MASTER_CONTROLLER: 'Seleccione un gerenciador',
  SELECT_A_MODULE: 'Seleccione un módulo',
  SELECT_AN_INPUT: 'Seleccione una entrada',
  NO_MASTER_CONTROLLERS_AVALIABLE: 'No existen gerenciadores disponibles',
  NO_MODULES_AVALIABLE: 'No existen módulos disponibles',
  NO_INPUTS_AVALIABLE: 'No existen entradas disponibles',
  DELETE_FLOOR_PLAN: 'Excluir planta baja',
  UPLOAD_NEW_IMAGE: 'Upload de nueva imagen',
  INVERT_COLORS: 'Invertir colores',
  DOCUMENTATION: 'Documentación',
  EDIT_FLOOR_PLAN: 'Editar planta baja',
  RETURN_TO_VISUALIZATION: 'Retornar para visualización',
  WANT_TO_DELETE_THE_FLOOR_PLAN: '¿Desea excluir la planta baja?',
  ACCESS_CALL_DEVICE_TYPE: 'Tipo del dispositivo',
  ACCESS_CALL_DEVICE_NAME: 'Nombre del dispositivo',
  ACCESS_CALL_ORIGIN_DEVICE_READER: 'Lector de acceso',
  ACCESS_CALL_ORIGIN_SELFSERVICE_TERMINAL: 'Terminal de autoservicio',
  ACCESS_CALL_GRANT_ACCESS: 'Permitir acceso',
  ACCESS_CALL_DENY_ACCESS: 'Negar acceso',
  ACCESS_CALL_LABEL: 'Llamada',
  ACCESS_CALL_OPEN_CALL: 'Abrir llamada',
  ACCESS_CALL_TIMEOUT_MSG_LT_MIN: 'Hace menos de 1 minuto',
  ACCESS_CALL_TIMEOUT_MSG_EQ_MIN: 'Hace {{elapsedTimeout}} minuto',
  ACCESS_CALL_TIMEOUT_MSG_GT_MIN: 'Hace {{elapsedTimeout}} minutos',
  ACCESS_CALL_ORIGIN_DEVICE_INPUT: 'Botonera',
  ACCESS_CALL_ACCESS_GRANTED: 'Acceso concedido',
  ACCESS_CALL_ACCESS_GRANTED_ERROR: 'No fue posible conceder el acceso',
  ACCESS_CALL_ACCESS_DENIED: 'Acceso negado',
  ACCESS_CALL_ACCESS_DENIED_ERROR: 'No fue posible negar el acceso',
  ACCESS_CALL_OPENED_CALLS: 'lLamadas en abierto',
  ACCESS_CALL_DETAILS_SELECTOR: 'Detalles',
  ACCESS_CALL_MESSAGES_SELECTOR: 'Mensajes',
  ACCESS_CALL_MESSAGE_SEND: 'Enviar',
  ACCESS_CALL_MESSAGE_NOT_SENT: 'Mensaje no enviado',
  ACCESS_CALL_MESSAGE_PLACEHOLDER: 'Introduzca aquí su mensaje',
  ACCESS_CALL_MESSAGE_CHARS_LEFT: '{{remainingChars}} caracteres restantes',
  NO_CAMERAS_RELATED: 'Ninguna cámara asociada.',
  ROLE_WITHOUT_PHYSICAL_LOCATION_RANGE: 'Su papel no posee alcance para ver las informaciones de ese lugar.',
  OBTAINING_INFORMATION_FROM_THE_SERVER: 'Obteniendo informaciones del servidor',
  NO_CAMERAS: 'Ninguna cámara.',
  LIVE: 'En vivo',
  TOUR_INITIAL: 'Ya que se interesó por la Portería virtual, ¿qué tal un tour para conocer algunos recursos?',
  TOUR_CAMERA: 'Primero, conozca el mosaico de las cámaras de las entradas y salidas. Usted puede cambiar la cámara que es exhibida.',
  TOUR_ACTIONS: 'Para que la persona pueda entrar, es necesario hacer la acción de Abrir puerta',
  TOUR_OPEN_ENTRANCES: 'Una vez que la persona ha dado entrada en la portería, es posible verla en las Entradas en abierto.',
  HIERARCHY: 'Jerarquía',
  INACTIVE: 'Inactivo',
  ACTIVE: 'Activo',
  STATUS: 'Estado',
  CURRENT_STATE: 'Estado actual',
  CARD_IS_NOT_PRESENT: 'La tarjeta no está presente en la lectora.',
  READER_IS_NOT_CONNECTED: 'No fue posible abrir un canal de comunicación con la lectora. Verifique si la lectora está conectada en el computador.',
  TOUR_CHECKOUT_INTRODUCTION: 'En el momento de que la persona se va , haga clic en Registro de salida',
  TOUR_CHECKOUT_STEP_1: 'Informe parte del nombre o documento de la persona, busquela, haga clic sobre su registro y después en Próximo.',
  TOUR_CHECKOUT_STEP_2: 'Confirme los datos y entonces use el botón Registrar salida.',
  TOUR_END: 'Ahora usted ya sabe como registrar una entrada, hacer una acción y controlar salidas por la Portería virtual. Continue usando el producto para conocer más recursos y verifique la <a href="{{docUrl}}" target="_blank"><i class="fa fa-external-link"></i>documentación</a> para informaciones sobre estas y otras funcionalidades.',
  USE_PRODUCT: 'Usar producto',
  ON: 'Encendido',
  OFF: 'Apagado',
  CRITICAL: 'Críticos',
  HIGH: 'Altos',
  MEDIUM: 'Medios',
  LOW: 'Bajos',
  PLANNED: 'Planificados',
  OPEN_OCCURRENCES: 'Occurrencias en abierto',
  DEVICES_STATUS: 'Estado de los dispositivos',
  ALARMED_INPUT_DEVICES: 'Entradas alarmadas',
  ALARMED_INPUT: 'Entrada alarmada',
  TOTAL_PEOPLE_PRESENT: 'Total de personas presentes',
  GRANTED: 'Permitidos',
  DENIED: 'Negados',
  REJECTED: 'Rechazados',
  ACCESSES: 'Accesos de la última hora',
  HOME: 'Inicio',
  YOU_ARE_OFFLINE_ATTEMPTING_TO_RECONNECT: 'Usted se encuentra fuera de línea. Intentando reconexión',
  AN_UNEXPECTED_ERROR_OCCURRED_ON_THE_SERVER_ATTEMPTING_TO_RECONNECT: 'Ocurrió un error inesperado en el servidor. Intentando reconexión',
  ACCESS_CALL_LOG: 'Log de llamadas de acceso',
  ASSOCIATED_LOBBY: 'Portería asociada',
  NO_CALLS_REGISTERED: 'Ninguna llamada registrada',
  FILTER: 'Filtrar',
  OPERATOR: 'Operador',
  CALL_STATUS: 'Estado de la llamada',
  ISSUE_ACCESS_CREDENTIAL: 'Entregar credencial',
  FINISHED_TIMED_OUT: 'Encerrada por timeout',
  FINISHED_ACCESS_ALLOWED: 'Encerrada con acceso permitido',
  FINISHED_ACCESS_DENIED: 'Encerrada con acceso negado',
  INITIAL_DATE: 'Fecha inicial',
  FINAL_DATE: 'Fecha final',
  CALL_DATE: 'Fecha de la llamada',
  NO_MOVEMENT: 'No hubo movimiento',
  PREVIOUS: 'Anterior',
  NEXT: 'Próximo',
  NO_DATA_ON_NEXT_PAGE: 'No hay mas registros en la próxima página',
  BLOCKED_PERSON_FOR_MORE_INFORMATION_ACCESS_THE_PERSON_REGISTRATION: 'Esa persona está bloqueada. Para más informaciones acceda al registro de la persona',
  ACCESS_DENIED_CUSTOM_VALIDATION: 'Por validación customizada',
  ACCESS_DENIED_BLOCK_PROVISORY_ON_EXIT: 'or bloqueo de provisorio en la salida',
  ACCESS_DENIED_CONTROLS_IN_BETWEEN_WORKDAYS: 'Acceso denegado por interjornada',
  TOUR_MONITORING_INTRODUCTION: 'El Security Hub es una solución completa para monitorear sus lugares físicos.',
  TOUR_MONITORING_STEP_1: 'En el Analytics, monitoree várias informaciones en tiempo real, como el estado de las ocurrencias a través del panel de Ocurrencias en Abierto',
  TOUR_MONITORING_STEP_2: 'Vea también el estado de los dispositivos y entradas alarmadas...',
  TOUR_MONITORING_STEP_3: '...Y confiera el flujo de Accesos de las personas a los lugares.',
  TOUR_MONITORING_STEP_4: 'Usted puede acceder los diferentes Lugares Físicos haciendo clic sobre cada uno dellos.',
  TOUR_MONITORING_STEP_5: 'Monitoree el estado de la Operación del lugar físico seleccionado.',
  TOUR_MONITORING_STEP_6: 'Verifique los Últimos Acessos a través de este panel.',
  TOUR_MONITORING_STEP_7: 'Sepa cuáles son las Ocurrencias relacionados al lugar físico seleccionado en el momento.',
  TOUR_MONITORING_STEP_8: 'Monitoree los Eventos de todos los dispositivos del lugar...',
  TOUR_MONITORING_STEP_9: '...Y también todos los Dispositivos de los lugares físicos.',
  TOUR_MONITORING_STEP_10: 'Você pode acessar outros locais físicos, clicando en uno de los Lugares Hijos',
  TOUR_MONITORING_STEP_11: 'Crie una Planta Baja, como un mapa que facilita el monitoreo',
  TOUR_MONITORING_STEP_12: 'Usted puede gerenciar su planta baja, a través del botón Editar',
  TOUR_MONITORING_STEP_13: 'Agregue dispositivos y lugares hijos haciendo clic en los íconos habilitados y arrastrándolos hasta la parte deseada de la planta baja.',
  TOUR_MONITORING_STEP_14: 'Usted también puede excluir la planta baja, hacer upload de una nueva imagem o invertir los colores de la imagen actual.',
  TOUR_MONITORING_STEP_15: 'Para acceder los detalles de un dispositivo o lugar hijo, haga clic sobre su ícono en la planta baja.',
  TOUR_MONITORING_STEP_16: 'Para volver la pantalla de Operaciones, haga clic en el botón Cerrar.',
  TOUR_MONITORING_STEP_17: 'Monitoree todas las Cámaras del lugar físico en un único lugar.',
  TOUR_MONITORING_STEP_18: 'Sepa quien son todas las Personas presentes en el lugar.',
  TOUR_MONITORING_END: 'Usted concluyo el tour de la solución de Monitoreo de Ocurrencias, ahora puede continuar a usar el producto normalmente.',
  USERNAME: 'Usuario',
  WEBSOCKET_DISCONNECTED: 'Perdimos la comunicación con el servidor, nos estamos reconectando...',
  EVENTS_MONITORING: 'Monitoreo de eventos',
  ACCESS_EVENTS: 'Monitoreo de registro de horas',
  NO_CLOCKIN_REGISTERED: 'Ningún registro de horas registrado',
  PIS: 'PIS',
  CPF: 'CPF',
  NSR: 'NSR',
  CAPACITY: 'Capacidad',
  PRESENT: 'Presentes',
  ACCESS_DENIED_WITHOUT_MASK: 'Por falta de máscara',
  ACCESS_DENIED_ABNORMAL_TEMPERATURE: 'Por temperatura anormal',
  DEVICE_MONITORING: 'Monitoreo de dispositivos',
  ITEMS_PER_PAGE: 'Registros por página',
  NEXT_PAGE: 'Página siguiente',
  PREVIOUS_PAGE: 'Página anterior',
  OF: 'de',
  SOUND_ALERT: 'Alerta sonora',
  FINALIZE: 'Finalizar',
  NORMALIZE_THIS_DEVICE_MESSAGE: '¿Desea realmente normalizar el estado de este aparato?',
  NORMALIZE_THIS_DEVICE_TITLE: 'Normalizar',
  NORMALIZE: 'Normalizar',
  ACCESS_DENIED_ROLE_RANGE: 'Denegado por franja horaria',
  VEHICLE_ACCESS_MONITORING: 'Supervisión de acceso de vehículos',
  UPDATE: 'Forzar actualización',
  PLATE: 'Placa',
  YOU_NOT_HAVE_LICENSE_TO_ACCESS_THIS_CONTENT: 'Usted no posee licencia para acceder ese contenido.'
};
