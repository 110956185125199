import { Injectable, Inject, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class FullscreenService {
  public exitFullscreenKeypress: EventEmitter<boolean> = new EventEmitter<
    boolean
    >();

  constructor(@Inject(DOCUMENT) private document: any) { }

  toggle(element: HTMLElement | any, isFullscreenElement: boolean) {
    if (this.isActive() && isFullscreenElement) {
      this.exitFullscreen();
    } else {
      this.fullscreen(element);
    }
  }

  isActive() {
    return (
      this.document.fullscreenElement ||
      this.document.webkitFullscreenElement ||
      this.document.mozFullScreenElement ||
      this.document.msFullscreenElement
    );
  }

  private exitFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      this.document.webkitCancelFullScreen();
    } else if (this.document.msExitFullscreen) {
      this.document.msExitFullscreen();
    }
  }

  private fullscreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }
}
