import { LocalTimeZone } from './local-time-zone.model';
import { Locale } from './locale.model';
import { Role } from './role.model';

export class Preferences {
  localTimeZone?: LocalTimeZone;
  locale?: Locale;
  userType?: number;
  ddi?: number;
  lobbyRole?: Role;
  cardTechnology?: number;
  biometryFactory?: number;
  applyingRangePhysicalLocation?: number;
  applyingRangeRole?: number;
  applyingRangeGroup?: number;
  personId?: number;
}
