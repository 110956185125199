import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SeniorDigitalService {
  public urlService;
  public endpoints;

  constructor(
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    this.urlService = this.cookieService.get('com.senior.services.url');
    this.endpoints = {
      getTour: `${this.urlService}rest/platform/digital/queries/getTour?name={{tourName}}`,
      updateTour: `${this.urlService}rest/platform/digital/queries/updateTour`
    };
  }

  getTour(tourId: string): Observable<any> {
    if (this.urlService) {
      return this.http.get<any>(
        this.endpoints.getTour.replace('{{tourName}}', tourId)
      );
    }
  }

  updateTour(tourId: string): Observable<any> {
    if (this.urlService) {
      const params = { name: tourId };
      return this.http.post(this.endpoints.updateTour, params);
    }
  }
}
