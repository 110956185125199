import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';

import { PermissionsService } from '@core/permissions/permissions.service';
import { Routines } from '@shared/enums/routines.enum';
import { RedirectService } from '../redirect/redirect.service';
import { RouteEnum } from '@shared/enums/routes.enum';

@Injectable()
export class CanLoadPermissions implements CanLoad {
  constructor(
    private permissions: PermissionsService,
    private redirectService: RedirectService,
    private router: Router
  ) { }

  canLoad(route: Route): boolean {
    if (this.permissions.isPermissionValid(this.getRoutine(route.path))) {
      return true;
    }
    this.redirect();
  }

  redirect() {
    if (this.permissions.isPermissionValid(Routines.VIRTUAL_LOBBY)) {
      this.router.navigate(['/', RouteEnum.VIRTUAL_LOBBY]);
    } else if (this.permissions.isPermissionValid(Routines.MONITORING)) {
      this.router.navigate(['/', RouteEnum.MONITORING]);
    } else {
      this.redirectService.toForbidden();
    }
  }
  getRoutine(path): number {
    switch (path) {
      case 'monitoring':
      case 'eventsMonitoring':
      case 'deviceSearch':
        return Routines.MONITORING;
      case 'virtualLobby':
        return Routines.VIRTUAL_LOBBY;
      case 'accessCallLog':
        return Routines.ACCESS_CALL_LOG;
      default:
        this.redirectService.toForbidden();
    }
  }
}
