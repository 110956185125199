import { Pipe, PipeTransform } from '@angular/core';
import { MaskingPipe } from './masking-pipe';
import { Person } from '@shared/models/person.model';

/**
 * Recebe uma objeto do tipo Person e retorna seu documento no formato DOCUMENT_TYPE + SEPARATOR + DOCUMENT_NUMBER
 */
@Pipe({
  name: 'formatPersonDocumentAndDocumentType'
})
export class PersonDocumentAndDocumentTypePipe implements PipeTransform {
  transform(person: Person, separator = ': ', _?): string {
    if (person.mandatoryDocuments) {
      const maskingPipe = new MaskingPipe();
      const documentTypeName = person.mandatoryDocuments[0].documentType.label;
      const documentTypeMask = person.mandatoryDocuments[0].documentType.mask;
      return (
        documentTypeName +
        separator +
        maskingPipe.applyMask(
          person.mandatoryDocuments[0].document,
          documentTypeMask
        )
      );
    }
    return '';
  }
}

@Pipe({
  name: 'personDocument'
})
export class PersonDocumentPipe implements PipeTransform {
  transform(person: any, _?): string {
    const maskingPipe = new MaskingPipe();
    const documentTypeMask = person.document.documentType.mask ? person.document.documentType.mask : '';
    return maskingPipe.applyMask(
      person.document.document,
      documentTypeMask
    );
  }
}

@Pipe({
  name: 'personDocumentType'
})
export class PersonDocumentTypePipe implements PipeTransform {
  transform(person: any, _?): string {
    return person.document.documentType.label;
  }
}

@Pipe({
  name: 'formatPerson'
})
export class PersonPipe implements PipeTransform {
  transform(person: any, _?): string {
    const personFormatter = new PersonDocumentAndDocumentTypePipe();
    return person.name + ' (' + personFormatter.transform(person) + ')';
  }
}
