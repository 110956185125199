import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { DeviceNode } from '@shared/models/deviceNode.model';
import { Vehicle } from '@shared/models/vehicle.model';

@Injectable()
export class VehicleService {
  public deviceNotifications: EventEmitter<DeviceNode[]> = new EventEmitter<DeviceNode[]>();

  private endpoints = {
    searchVehicle: `${environment.urlBackend
      }/vehicle/search/licenseplate?page=1&pageSize=10&term={term}`
  };

  constructor(private http: HttpClient) { }

  public searchVehicle(search: string): Observable<Vehicle[]> {
    if (search.length < 3) {
      return;
    }
    return this.http.get<Vehicle[]>(this.endpoints.searchVehicle.replace('{term}', search));
  }
}
