import {
  EventEmitter,
  Component,
  ViewEncapsulation,
  Input,
  Output
} from '@angular/core';
import { Incident } from '@shared/models/incident.model';

@Component({
  selector: 'app-incident-card',
  templateUrl: './incident-card.component.html',
  styleUrls: ['./incident-card.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class IncidentCardComponent {
  @Input() incidents: Incident[];
  @Output() onClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  onClickCard(incident) {
    this.onClick.emit(incident);
  }
}
