import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { of as observableOf, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '@environment/environment';
import { PhysicalLocation } from '@shared/models/physical-location.model';
import { Camera } from '@shared/models/camera.model';
import { WebSocketService } from '@core/web-socket/web-socket.service';
import { TopicChannel } from '@shared/enums/topic-channel.enum';

@Injectable()
export class PhysicalLocationService {
  private endpoints = {
    physicalLocationChildren: `${environment.urlBackend}/physicallocation/{id}/children?status={status}`,
    physicalLocation: `${environment.urlBackend}/physicallocation/{id}`,
    physicalLocationPath: `${environment.urlBackend}/physicallocation/{id}/path`,
    totalPeoplePresent: `${environment.urlBackend}/monitoring/physicallocation/{id}/peoplePresent/total`,
    searchPhysicalLocation: `${environment.urlBackend}/physicallocation/search?term={term}&situation={situation}`,
    physicalLocationWithoutRange: `${environment.urlBackend}/monitoring/physicallocation/{id}/withoutrange`,
    cameraByIdPhysicalLocation: `${environment.urlBackend}/monitoring/physicallocation/{id}/camera`
  };

  constructor(private http: HttpClient, private webSocketService: WebSocketService) { }

  getPhysicalLocationChildren(id: number = 0): Observable<PhysicalLocation[]> {
    const active = '1';
    let endpoint = this.endpoints.physicalLocationChildren;
    endpoint = endpoint.replace('{status}', active);
    endpoint = endpoint.replace('{id}', id.toString());
    let queryParams = new HttpParams();
    queryParams = queryParams.append('rangeFilter', 'VALID_RANGE_IN_HIERARCHY');
    return this.http.get<PhysicalLocation[]>(endpoint, { params: queryParams });
  }

  searchPhysicalLocation(search: string): Observable<PhysicalLocation[]> {
    let endpoint = this.endpoints.searchPhysicalLocation;
    const onlyActives = 1;
    endpoint = endpoint.replace('{term}', search);
    endpoint = endpoint.replace('{situation}', onlyActives.toLocaleString());
    return this.http.get<PhysicalLocation[]>(endpoint);
  }

  getPhysicalLocation(id: number = 0): Observable<PhysicalLocation> {
    const endpoint = this.endpoints.physicalLocation.replace('{id}', id.toString());
    return this.http.get<PhysicalLocation>(endpoint);
  }

  getTotalPresentPeople(id: number = 0): Observable<number> {
    const endpoint = this.endpoints.totalPeoplePresent.replace('{id}', id.toString());
    return this.http.get<number>(endpoint).pipe(catchError(() => observableOf(0)));
    // Retornando zero quando acontecer algu erro,
    // pois esse serviço pode ser chamado sem que o usuário tenha abrangência do local
  }

  getPhysicalLocationPath(id: number = 0): Observable<PhysicalLocation[]> {
    const endpoint = this.endpoints.physicalLocationPath.replace(
      '{id}',
      id.toString()
    );
    return this.http.get<PhysicalLocation[]>(endpoint);
  }

  getPhysicalLocationWithoutRangeValidation(
    id: number = 0
  ): Observable<PhysicalLocation> {
    const endpoint = this.endpoints.physicalLocationWithoutRange.replace('{id}', id.toString());
    return this.http.get<PhysicalLocation>(endpoint);
  }

  getPhysicalLocationChildrenWithoutRangeValidation(id: number = 0): Observable<PhysicalLocation[]> {
    const active = '1';
    let endpoint = this.endpoints.physicalLocationChildren;
    endpoint = endpoint.replace('{status}', active);
    endpoint = endpoint.replace('{id}', id.toString());
    return this.http.get<PhysicalLocation[]>(endpoint);
  }

  public monitorTotalPresentPeople(id: number) {
    return this.webSocketService.observeTopic(TopicChannel.PEOPLE, 'physical-location')
      .pipe(this.webSocketService.filterTopicByPhysicalLocations(+id))
      .pipe(switchMap(() => {
        return id === 0 ? [] : this.getTotalPresentPeople(id);
      }));
  }

  getCameraByPhysicalLocationId(id: number = 0): Observable<Camera[]> {
    const endpoint = this.endpoints.cameraByIdPhysicalLocation.replace(
      '{id}',
      id.toString()
    );
    return this.http.get<Camera[]>(endpoint);
  }
}
