import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PhysicalLocationService } from './physical-location.service';
import { Observable } from 'rxjs';
import { PhysicalLocation } from '@shared/models/physical-location.model';

@Injectable()
export class PhysicalLocationResolver implements Resolve<PhysicalLocation[]> {
  constructor(private physicalLocationService: PhysicalLocationService) { }

  resolve(): Observable<PhysicalLocation[]> {
    return this.physicalLocationService.getPhysicalLocationChildren();
  }
}
