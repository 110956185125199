import { Role } from '@shared/models/role.model';
import { Person } from '@shared/models/person.model';

export class EntryMovement {
  constructor(
    public virtualLobbyId: number,
    public visitor: Person,
    public visitorRole: Role,
    public visitorRoleEndDate: any,
    public employee: Person,
    public accessCallId?: number
  ) { }

  static nullInstance() {
    return new EntryMovement(
      0,
      Person.nullInstance(),
      null,
      '',
      Person.nullInstance()
    );
  }
}
