import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { GrowlModule } from 'primeng/primeng';
import { CommonModule } from '@angular/common';
import { LoadingModule } from 'ngx-loading';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { AccessCallDetailsDrawerComponent } from './access-call-details-drawer.component';
import { AccessCallDetailsComponent } from './access-call-details/access-call-details.component';
import { AccessCallMessagesComponent } from './access-call-messages/access-call-messages.component';
import { DetailsMessageSelectorComponent } from './details-message-selector/details-message-selector.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatSidenavModule,
    GrowlModule,
    LoadingModule
  ],
  declarations: [
    AccessCallDetailsDrawerComponent,
    AccessCallMessagesComponent,
    DetailsMessageSelectorComponent,
    AccessCallDetailsComponent
  ],
  exports: [
    AccessCallDetailsDrawerComponent
  ]
})
export class AccessCallDetailsDrawerModule { }
