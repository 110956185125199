<div class="nav-bar">
    <div class="nav-bar__icon--top">
        <button id="navBarMonitor" class="nav-bar__button--monitoring" [routerLink]="['monitoring']"
            [routerLinkActive]="['activated']" pTooltip="{{'WEB_MONITOR' | translate}}"
            tooltipStyleClass="app_tooltip right">
        </button>
        <button id="navBarDeviceSearch" class="nav-bar__icon--button--devices" [routerLink]="['deviceSearch']"
            [routerLinkActive]="['activated']" pTooltip="{{'DEVICE_MONITORING' | translate}}"
            tooltipStyleClass="app_tooltip right">
        </button>
        <button id="navBarAccessMonitoring" class="nav-bar__icon--button--access" [routerLink]="['eventsMonitoring']"
            [routerLinkActive]="['activated']" pTooltip="{{'EVENTS_MONITORING' | translate}}"
            tooltipStyleClass="app_tooltip right">
        </button>
        <button id="navBarVirtualLobby" class="nav-bar__icon--button--virtual-lobby" [routerLink]="['virtualLobby']"
            [routerLinkActive]="['activated']" *ngIf="checkLicenceVirtualLobbie()"
            pTooltip="{{'VIRTUAL_LOBBY' | translate}}" tooltipStyleClass="app_tooltip right">
        </button>
        <button id="navBarAccessLog" class="nav-bar__icon--button--access-log" [routerLink]="['accessCallLog']"
            [routerLinkActive]="['activated']" *ngIf="checkLicenceVirtualLobbie()"
            pTooltip="{{'ACCESS_CALL_LOG' | translate}}" tooltipStyleClass="app_tooltip right">
        </button>
    </div>

    <div class="nav-bar__icon--bottom">
        <div class="ui-g-12" *ngIf="checkLicenceIncident()">
            <button class="nav-bar__icon--button" pTooltip="{{'NOTIFICATIONS' | translate}}"
                tooltipStyleClass="app_tooltip right">
                <navbar-incident-bell [autorizationAudioNotification]="autorizationAudioNotification"
                    (callback)="changeSoundAlertIcon()"></navbar-incident-bell>
            </button>
            <button class="nav-bar__icon--button" (click)='changeSoundAlertIcon()'
                pTooltip="{{'SOUND_ALERT' | translate}}" tooltipStyleClass="app_tooltip right">
                <em [class]="autorizationAudioNotification ? 'ng-fa-icon fa fa-volume' : 'ng-fa-icon fa fa-volume-mute'"></em>
            </button>
        </div>
        <button class="nav-bar__icon--button" (click)="openDocumentation()" pTooltip="{{'HELP' | translate}}"
            tooltipStyleClass="app_tooltip right">
            <em class="icon fa fa-question-circle-o ng-fa-icon"></em>
        </button>
        <button class="nav-bar__icon--button" (click)="toggleFullscreen()">
            <div class="icon fa fa-arrows-alt"></div>
        </button>
        <button class="nav-bar__icon--button user" pTooltip="{{'LOGOFF' | translate}}"
            tooltipStyleClass="app_tooltip right">
            <em *ngIf="!photo || photo.length === 0" class="icon fa fa-user-circle-o img-circle--user"></em>
            <div class="img-circle" *ngIf="photo && photo.length">
                <img class="img-circle--user" alt="" width="" height="" src="{{photo}}">
            </div>
            <div class="side-bar-nav__logout-content" (click)="logout()">
                <em class="fa fa-sign-out side-bar-nav__logout-icon"></em>
            </div>
        </button>
    </div>
</div>