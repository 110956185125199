import {
  Component,
  Input,
  AfterViewInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';

export enum DetailsMessageOptions {
  MESSAGES = 'MESSAGES',
  DETAILS = 'DETAILS'
}

@Component({
  selector: 'app-details-message-selector',
  templateUrl: './details-message-selector.component.html',
  styleUrls: ['./details-message-selector.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DetailsMessageSelectorComponent implements AfterViewInit {
  public _optionSelected: DetailsMessageOptions;
  @Output()
  public optionSelectedChange = new EventEmitter<DetailsMessageOptions>();
  @Input()
  get optionSelected(): DetailsMessageOptions {
    return this._optionSelected;
  }
  set optionSelected(option: DetailsMessageOptions) {
    if (option === DetailsMessageOptions.DETAILS) {
      this.setDetailsActive();
      this._optionSelected = DetailsMessageOptions.DETAILS;
    } else {
      this.setMessagesActive();
      this._optionSelected = DetailsMessageOptions.MESSAGES;
    }
    this.optionSelectedChange.emit(this._optionSelected);
  }

  @ViewChild('detailsOption', { static: false }) public detailsOptionElRef: ElementRef;
  @ViewChild('messagesOption', { static: false }) public messagesOptionElRef: ElementRef;
  private detailsOptionEl: HTMLElement;
  private messagesOptionEl: HTMLElement;

  constructor() { }

  ngAfterViewInit() {
    if (this.detailsOptionElRef) {
      this.detailsOptionEl = this.detailsOptionElRef.nativeElement;
    }
    if (this.messagesOptionElRef) {
      this.messagesOptionEl = this.messagesOptionElRef.nativeElement;
    }
  }

  selectDetails() {
    this.optionSelected = DetailsMessageOptions.DETAILS;
  }

  selectMessages() {
    this.optionSelected = DetailsMessageOptions.MESSAGES;
  }

  setDetailsActive() {
    setTimeout(() => {
      if (this.detailsOptionEl) {
        this.detailsOptionEl.classList.add(
          'details-message-selector__option--active'
        );
      }
      if (this.messagesOptionEl) {
        this.messagesOptionEl.classList.remove(
          'details-message-selector__option--active'
        );
      }
    });
  }

  setMessagesActive() {
    setTimeout(() => {
      if (this.detailsOptionEl) {
        this.detailsOptionEl.classList.remove(
          'details-message-selector__option--active'
        );
      }
      if (this.messagesOptionEl) {
        this.messagesOptionEl.classList.add(
          'details-message-selector__option--active'
        );
      }
    });
  }
}
