<section #notificationBanner *ngIf="isBannerShowing" class="access-call access-call--showing">
  <div class="access-call__section--decoration">
    <img src="{{getOpenCallIcon()}}" class="access-call__icon margin-r-1">
    <div class="access-call__section">
      <span class="access-call__section__title">
        {{accessCall?.device?.name}}
      </span>
      {{accessCall?.virtualLobby?.name}}
    </div>
  </div>
  <div *ngIf="accessCallHasPerson()" class="access-call__section--decoration">
    <span #notificationBannerPersonPhoto class="access-call__section__person-photo margin-r-1"></span>
    <div class="access-call__section">
      <span class="access-call__section__title">
        {{accessCall?.person?.name}}
      </span>
      {{accessCall?.person | formatPersonDocumentAndDocumentType: ' - '}}
    </div>
  </div>
  <div class="access-call__section">
    <span class="access-call__section__title">
      {{accessCall?.accessValidation?.reason | translate}}
    </span>
  </div>
  <div class="access-call__section">
    <button *ngIf="!timedOut" (click)="answerCall()" class="app-btn access-call__section__cta-btn">
      {{'ACCESS_CALL_OPEN_CALL' | translate}}
    </button>
  </div>
  <div *ngIf="timedOut" class="access-call__section">
    <span class="access-call__timeout-since">
      {{minutesSinceTimeoutMsg}}
    </span>
  </div>
  <div *ngIf="timedOut" class="access-call__section">
    <button class="app-btn access-call__section__close-btn" (click)="hideBanner()">
    </button>
  </div>
</section>