export enum TopicChannel {
  ACCESS_CALL = 'accessCallWebSocketNotification',
  DEVICE = 'deviceWebSocketNotification',
  ACCESS = 'accessWebSocketNotification',
  PEOPLE = 'peoplePresentWebSocketNotification',
  INCIDENT = 'incidentWebSocketNotification',
  CLOCK_IN = 'clockInWebSocketNotification',
  ALARM = 'alarmWebSocketNotification',
  VEHICLE = 'vehicleAccessWebSocketNotification'
}
