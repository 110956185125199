import { AccessCallStatus } from '@shared/models/access-call-status.model';
import { IdModel } from '@shared/models/id-model.model';
import { VirtualLobby } from '@shared/models/virtual-lobby.model';
import { Person } from '@shared/models/person.model';
import { Device } from '@shared/models/device.model';
import { IdNamedModel } from '@shared/models/id-named-model.model';
import { AccessValidation } from '@shared/models/access-validation.model';
import { Role } from '@shared/models/role.model';
import { PersonSituation } from '@shared/models/person-situation.model';
import { AccessCallOrigin } from '@shared/enums/access-call-origin.enum';

export class AccessCall implements IdModel {
  public id: number;
  public createDate: Date;
  public status: AccessCallStatus;
  public origin: AccessCallOrigin;
  public operator: IdNamedModel;
  public virtualLobby: VirtualLobby;
  public person: Person;
  public device: Device;
  public selfServiceTerminal: IdNamedModel;
  public accessValidation: AccessValidation;
  public personActiveRoles: Role[];
  public personPhotoUrl: string;
  public personSituation: PersonSituation;
  public movementType: string;

  public static fromJson(json) {
    // Essa função será removida #RON-2005 que solucionará problemas com tipagem
    const call = Object.create(AccessCall.prototype);
    const accessCall = Object.assign(call, json);
    accessCall.person = Object.assign(Person.nullInstance(), accessCall.person);
    return accessCall;
  }
}
