import { Document } from './document.model';
import { Email } from './email.model';
import { Phone } from './phone.model';
import { IdNamedModel } from '@shared/models/id-named-model.model';

export class Person implements IdNamedModel {
  public static readonly NULL_INSTANCE_ID = -1;
  public static readonly NULL_INSTANCE_NAME = '';

  constructor(public id: number, public name: string) { }

  firstMandatoryDocument: Document;
  mandatoryDocuments: Document[];
  preferentialEmail: Email;
  preferentialPhone: Phone;
  documents: Document[];
  emails: Email[];
  phones: Phone[];
  gender: number;
  birthday: string;
  registry: string;
  registerDate: string;
  lastModificationDate: string;
  address: string;
  district: string;
  city: string;
  uf: string;
  cep: string;
  nationality: string;
  lastAccessDate: string;
  situation: number;
  hasValidRole: boolean;
  hasProvisoryRole: boolean;
  photoUrl: string;
  number: string;

  static nullInstance() {
    const person: Person = new Person(-1, '');
    person.firstMandatoryDocument = Document.nullInstance();
    return person;
  }

  isNull() {
    return (
      this.id === Person.NULL_INSTANCE_ID &&
      this.name === Person.NULL_INSTANCE_NAME
    );
  }

  addDocument(document: Document) {
    if (this.documents) {
      this.documents.push(document);
    }
  }

  addEmail(email: Email) {
    if (this.emails) {
      this.emails.push(email);
    }
  }

  addPhone(phone: Phone) {
    if (this.phones) {
      this.phones.push(phone);
    }
  }

  getPreferentialPhone(): string {
    if (this.preferentialPhone) {
      return this.preferentialPhone.phoneNumber;
    }
    if (this.phones && this.phones.length) {
      const pref = this.phones.find(e => e.preferential);
      return pref ? pref.phoneNumber : undefined;
    }
    return undefined;
  }

  getPreferentialEmail(): string {
    if (this.preferentialEmail) {
      return this.preferentialEmail.emailAddress;
    }
    if (this.emails && this.emails.length) {
      const pref = this.emails.find(e => e.preferential);
      return pref ? pref.emailAddress : '';
    }
    return '';
  }
}
