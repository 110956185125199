import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Person } from '@shared/models/person.model';
import { PersonService } from '@core/person/person.service';

@Injectable()
export class PersonResolver implements Resolve<Person> {
  constructor(private personService: PersonService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Person> {
    return this.personService.getPersonById(route.params.personId);
  }
}
