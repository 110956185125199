<!-- PESSOA BLOQUEADA-->
<div *ngIf="hasPerson() && isBlockedPerson()">
  <div class="access-call-drawer__section">
    <div class="flex-centered margin-b-1">
      <div #drawerPersonPhoto class="access-call-drawer__person-photo access-call-drawer__person-photo--blocked">
      </div>
    </div>
    <span class="access-call-drawer__person-name access-call-drawer__person-name--blocked">
      {{accessCall?.person?.name}}
    </span>
  </div>
  <div class="flex-centered flex-column margin-t-2">
    <div class="access-call-drawer__blocked-reason margin-b-2">
      {{accessCall?.personSituation?.blockReason}}
    </div>
    <div class="access-call-drawer__blocked-procedures padding-1">
      {{accessCall?.personSituation?.unblockProcedure}}
    </div>
    <button (click)="denyAccess()" class="app-btn bg-color-error font-size-1 margin-t-2">
      {{'ACCESS_CALL_DENY_ACCESS' | translate}}
    </button>
  </div>
</div>

<!-- PESSOA NÃO BLOQUEADA-->
<div *ngIf="!isBlockedPerson()">

  <div *ngIf="hasPerson()" class="access-call-drawer__section">
    <div class="flex-centered margin-b-1">
      <div #drawerPersonPhoto class="access-call-drawer__person-photo">
      </div>
    </div>
    <span class="access-call-drawer__person-name">
      {{accessCall?.person?.name}}
    </span>
    <span *ngIf="accessCall?.accessValidation?.allowed" class="access-call-drawer__person-status-ok">
      {{personAccessValidationReason}}
    </span>
    <span *ngIf="!accessCall?.accessValidation?.allowed" class="access-call-drawer__person-status-nok">
      {{personAccessValidationReason}}
    </span>
    <div *ngIf="hasPerson()" class="access-call-drawer__section--columns margin-t-2">
      <div class="access-call-drawer__section__column">
        <label class="access-call-drawer__label">{{'DOCUMENT_TYPE' | translate}}</label>
        <span class="margin-b-1">{{accessCall?.person?.mandatoryDocuments[0]?.documentType?.label}}</span>
        <label class="access-call-drawer__label">{{'DOCUMENT' | translate}}</label>
        <span class="margin-b-1">{{accessCall?.person?.mandatoryDocuments[0]?.document}}</span>
      </div>
      <div class="access-call-drawer__section__column">
        <label *ngIf="hasActiveRoles()" class="access-call-drawer__label">{{'ROLE' | translate}}</label>
        <span *ngFor="let role of accessCall?.personActiveRoles" class="margin-b-1">{{role.name}}</span>
      </div>
    </div>
  </div>
  <div class="access-call-drawer__section">
    <div class="access-call-drawer__section--columns">
      <div class="access-call-drawer__section__column">
        <label class="access-call-drawer__label">{{'ACCESS_CALL_DEVICE_TYPE' | translate}}</label>
        <span class="margin-b-1">{{originDevice}}</span>
        <label class="access-call-drawer__label">{{'PHYSICAL_LOCATION' | translate}}</label>
        <span class="margin-b-1">{{accessCall?.virtualLobby?.physicalLocation?.name}}</span>
      </div>
      <div class="access-call-drawer__section__column">
        <label class="access-call-drawer__label">{{'ACCESS_CALL_DEVICE_NAME' | translate}}</label>
        <span class="margin-b-1">{{accessCall?.device?.name}}</span>
      </div>
    </div>
    <div class="flex-centered margin-t-2">
      <button (click)="allowAccess()" class="app-btn app-splited-btn bg-color-success font-size-1">
        {{'ACCESS_CALL_GRANT_ACCESS' | translate}}
      </button>
      <button (click)="denyAccess()" class="app-btn app-splited-btn bg-color-error font-size-1">
        {{'ACCESS_CALL_DENY_ACCESS' | translate}}
      </button>
    </div>
  </div>

</div>
<ngx-loading [show]="isLoading"></ngx-loading>