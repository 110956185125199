import {
  Component,
  ViewEncapsulation,
  EventEmitter,
  Output,
  OnInit
} from '@angular/core';
import { AccessCall } from '@shared/models/access-call.model';
import { TranslateService } from '@ngx-translate/core';
import { AccessCallService } from '@core/access-call/access-call.service';
import { DetailsMessageOptions } from '@shared/components/access-call-details-drawer/details-message-selector/details-message-selector.component';
import { AccessCallOrigin } from '@shared/enums/access-call-origin.enum';

@Component({
  selector: 'app-access-call-details-drawer',
  templateUrl: './access-call-details-drawer.component.html',
  styleUrls: ['./access-call-details-drawer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AccessCallDetailsDrawerComponent implements OnInit {
  @Output() public drawerClosed = new EventEmitter<any>();
  @Output() public drawerOpened = new EventEmitter<any>();
  public detailsOrMessagesOption = DetailsMessageOptions.DETAILS;
  public containerShowing = false;
  public opened = false;
  public accessCall: AccessCall;
  public isLoading = false;
  private closeTimeoutId;
  private denyOrAccessTimeout;

  constructor(
    public translateService: TranslateService,
    public accessCallService: AccessCallService
  ) { }

  ngOnInit() {
    this.accessCallService.onAccessCallDetailsOpen().subscribe(call => {
      if ((call && !call.device) || !call.virtualLobby) {
        throw new Error('As properties device e currentValue são obrigatorias');
      }
      this.accessCall = call;
      this.refreshOpenClose('open');
    });
  }

  deviceAcceptsMessage(): boolean {
    if (!this.accessCall || !this.accessCall.origin) {
      return false;
    }
    if (this.accessCall.origin === AccessCallOrigin.DEVICE_READER) {
      return true;
    }
    if (this.accessCall.origin === AccessCallOrigin.SELFSERVICE_TERMINAL) {
      return true;
    }
    return false;
  }

  public showDetails() {
    return this.detailsOrMessagesOption === DetailsMessageOptions.DETAILS;
  }

  public showMessages() {
    return this.detailsOrMessagesOption === DetailsMessageOptions.MESSAGES;
  }

  public closeDrawer() {
    this.isLoading = false;
    this.refreshOpenClose('close');
  }

  public onAccessAllowedOrDenied() {
    // aguarda 1s antes de fechar o drawer, para que o usuário veja o growl de feedback
    this.denyOrAccessTimeout = setTimeout(
      () => this.refreshOpenClose('close'),
      1000
    );
  }

  /**
   * O drawer do angular material2 opera de tal forma que um container
   * necessário para o funcionamento do drawer sempre fica na DOM, mesmo
   * quando o drawer está com opened=false. Para que o componente funcione
   * como esperado: presente na DOM apenas quando opened=true, é
   * necessário utilizar uma flag de controle adicional e controlar as
   * duas em conjunto.
   * @param openOrClosed
   */
  private refreshOpenClose(openOrClosed: 'open' | 'close') {
    const openOrCloseAnimationWindow = 200;
    if (openOrClosed === 'close') {
      this.opened = false;
      this.closeTimeoutId = setTimeout(() => {
        this.drawerClosed.emit();
        this.containerShowing = false;
        this.accessCall = undefined;
      }, openOrCloseAnimationWindow);
    } else {
      this.detailsOrMessagesOption = DetailsMessageOptions.DETAILS;
      if (this.closeTimeoutId) {
        clearTimeout(this.closeTimeoutId);
        this.closeTimeoutId = undefined;
      }
      if (this.denyOrAccessTimeout) {
        clearTimeout(this.denyOrAccessTimeout);
        this.denyOrAccessTimeout = undefined;
      }
      this.containerShowing = true;
      this.opened = false;
      setTimeout(() => {
        this.drawerOpened.emit();
        this.opened = true;
      }, openOrCloseAnimationWindow);
    }
  }
}
