import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Access } from '@shared/models/access.model';
import { MonitoringService } from './monitoring.service';
import { AccessTypeFilter } from '@events-monitoring/core/enums/access-type-filter.enum';

@Injectable()
export class MonitoringResolver implements Resolve<Access[]> {
  constructor(private monitoringService: MonitoringService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Access[]> {
    let accessTypeFilterGroup = AccessTypeFilter.ALL;
    const accessTypeParam = route.queryParams['accessType'];
    if (accessTypeParam) {
      accessTypeFilterGroup = accessTypeParam;
    }
    const params = {
      physicalLocationId: [],
      readerDeviceId: [],
      personId: [],
      roleId: [],
      groupId: [],
      accessCount: 50,
      accessTypeFilterGroup: accessTypeFilterGroup
    };
    return this.monitoringService.getAllAccesses(params);
  }
}
