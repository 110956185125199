import { Component, ViewEncapsulation, Inject, OnInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PersonService } from '@core/person/person.service';
import { PermissionsService } from '@core/permissions/permissions.service';
import { RedirectService } from '@core/redirect/redirect.service';
import { PermissionRoutine } from '@shared/models/permission-routine.model';
import { Routines } from '@shared/enums/routines.enum';
import { RouteEnum } from '@shared/enums/routes.enum';
import { DocumentationEnum } from '@shared/enums/documentation.enum';
import { TenantLicense } from '@shared/models/tenant-license.model';
import { FullscreenService } from '@core/fullscreen/fullscreen.service';
import { StorageService } from '@core/storage/storage.service';
import { faVolumeMute, faVolumeDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class NavBarComponent implements OnInit, OnDestroy {
  showSideBar = false;
  photo: string;
  permissionRoutineList: PermissionRoutine[] = [];
  permissionsRoutine = Routines;
  documentationUrl: string;
  autorizationAudioNotification = false;
  faVolumeMute = faVolumeMute;
  faVolumeDown = faVolumeDown;

  private ngUnsubscribe = new Subject();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private personService: PersonService,
    private permissionsService: PermissionsService,
    private redirectService: RedirectService,
    private router: Router,
    private translate: TranslateService,
    private fullscreenService: FullscreenService,
    private storageService: StorageService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const mainRoute = event.url.split('/')[1];
        this.documentationUrl = this.defineDocumentationByRoute(mainRoute);
      }
    });
  }

  ngOnDestroy() {
    if (this.ngUnsubscribe) {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }
  }

  defineDocumentationByRoute(route) {
    switch (route) {
      case RouteEnum.MONITORING:
      case RouteEnum.ACCESS_MONITORING:
      case RouteEnum.DEVICE_SEARCH:
        return DocumentationEnum.DOC_URL_MONITORING;
      case RouteEnum.VIRTUAL_LOBBY:
      case RouteEnum.ACCESS_CALL_LOG:
        return DocumentationEnum.DOC_URL_VIRTUAL_LOBBY;
      default:
        return DocumentationEnum.DOC_URL_MONITORING;
    }
  }

  openDocumentation() {
    this.translate.get(this.documentationUrl)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(url => {
        window.open(url);
      });
  }

  ngOnInit() {
    this.photo = '';
    const preferences = this.storageService.getPreferences();
    this.personService
      .getPersonPhotoByPersonId(preferences.personId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        this.photo = response.photoURL;
      });
    this.permissionRoutineList = this.permissionsService.getUserPermissions();
  }

  public checkLicenceVirtualLobbie(): boolean {
    const tenant: TenantLicense = this.storageService.getTenant();
    return tenant && tenant.licenceNumberVirtualLobbies > 0;
  }

  public checkLicenceIncident(): boolean {
    const tenant: TenantLicense = this.storageService.getTenant();
    return tenant && (
      tenant.licenceNumberIncidentsUsers > 0 || this.checkTrialIncident(tenant)
    );
  }

  private checkTrialIncident(tenant: TenantLicense): boolean {
    return tenant.trials?.some(trial => trial.type === 'INCIDENT_MANAGEMENT' && trial.status === 'ENABLE');
  }

  toggleFullscreen() {
    this.fullscreenService.toggle(this.document.documentElement, true);
  }

  logout() {
    this.redirectService.toLogin();
  }

  changeSoundAlertIcon() {
    this.autorizationAudioNotification = !this.autorizationAudioNotification;
  }
}
