import { NgModule, Optional, SkipSelf } from '@angular/core';

import { MessageService } from 'primeng/components/common/messageservice';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { RedirectService } from './redirect/redirect.service';
import { G7Service } from './g7/g7.service';
import { HttpErrorHandler } from './http-error-handler/http-error-handler.service';
import { DateTimeFormatService } from './date-time-format/date-time-format.service';
import { PhysicalLocationService } from './physical-location/physical-location.service';
import { PhysicalLocationResolver } from './physical-location/physical-location.resolver';
import { PreferencesService } from './preferences/preferences.service';
import { MonitoringService } from './monitoring/monitoring.service';
import { MonitoringResolver } from './monitoring/monitoring.resolver';
import { GroupService } from './group/group.service';
import { DeviceService } from './device/device.service';
import { VirtualLobbyService } from './virtual-lobby/virtual-lobby.service';
import { RoleService } from './role/role.service';
import { PermissionsService } from './permissions/permissions.service';
import { CanLoadPermissions } from './can-load-permissions/can-load-permissions';
import { IncidentService } from './incident/incident.service';
import { PersonService } from './person/person.service';
import { PersonResolver } from './person/person.resolve';
import { CameraService } from './camera/camera.service';
import { StorageService } from './storage/storage.service';
import { ServerService } from './server/server.service';
import { AccessCallService } from './access-call/access-call.service';
import { FullscreenService } from './fullscreen/fullscreen.service';
import { CardReaderService } from './card-reader/card-reader.service';
import { SeniorDigitalService } from './senior-digital/senior-digital.service';
import { MixPanelService } from './mix-panel/mix-panel.service';
import { TenantService } from '@core/tenant/tenant.service';
import { CredentialService } from '../virtual-lobby/new-credential/new-credential.service';
import { CookieService } from 'ngx-cookie-service';
import { WebSocketService } from './web-socket/web-socket.service';
import { GoogleAnalyticsService } from './google-analytics/google-analytics.service';
import { VehicleService } from './vehicle/vehicle.service';

@NgModule({
  imports: [],
  providers: [
    G7Service,
    RedirectService,
    HttpErrorHandler,
    PhysicalLocationService,
    PhysicalLocationResolver,
    PreferencesService,
    DateTimeFormatService,
    MonitoringService,
    MonitoringResolver,
    GroupService,
    DeviceService,
    VehicleService,
    VirtualLobbyService,
    PermissionsService,
    CanLoadPermissions,
    IncidentService,
    PersonService,
    RoleService,
    PersonResolver,
    CameraService,
    StorageService,
    ServerService,
    AccessCallService,
    FullscreenService,
    MessageService,
    CardReaderService,
    SeniorDigitalService,
    MixPanelService,
    TenantService,
    CredentialService,
    CookieService,
    WebSocketService,
    GoogleAnalyticsService
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
