<div class="incident-bell--events" (click)="toggleNotifications()">
    <div class="incident-bell--totalizer" *ngIf="hasNotifications()">
        <ng-container *ngIf="notifications.length <= 9">{{notifications.length}} </ng-container>
        <ng-container *ngIf="notifications.length > 9">+{{notifications.length - 1}}</ng-container>
    </div>
    <i class="icon fa fa-bell-o"></i>
</div>

<div class="incident-bell--notifications" *ngIf="showNotifications">
    <div class="incident-bell__header" *ngIf="hasCloseHeader()" (click)="closeNotifications()">{{'CLOSE_NOTIFICATIONS' | translate}}</div>
    <div class="incident-bell__header" *ngIf="hasExpandHeader()" (click)="expandNotifications()">{{'VIEW_MORE' | translate}}</div>
    <app-incident-card [incidents]="getNotifications()" (onClick)="onClickIncident($event)"></app-incident-card>
    <div *ngIf="!hasNotifications()" class="incident-bell--notifications-empty">
        <i class="fa fa-info-circle"></i>
        {{'NO_NOTIFICATIONS' | translate}}
    </div>
</div>
