import { DocumentType } from './document-type.model';

export class Document {
  constructor(
    public id: number,
    public documentType: DocumentType,
    public document: string
  ) { }

  static nullInstance() {
    return new Document(-1, DocumentType.nullInstance(), '');
  }
}
