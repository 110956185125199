<div class="incidents__card ui-g-12 ui-g-nopad" *ngFor="let incident of incidents">
    <div class="ui-g" (click)="onClickCard(incident)">
        <div class="ui-g-12 ui-g-nopad incidents__title">
            <span>{{incident.incidentType.name}}</span>
        </div>
        <div class="ui-g-12 ui-g-nopad">
            <span class="incidents__elapsed-time">{{incident.date | dateFromNow}}</span>
        </div>
        <div class="incidents__details ui-g-12 ui-g-nopad">
            <div class="incidents__details--priority" [ngClass]="incident.priority">
            <span class="incidents__details--priority-text">{{ 'PRIORITY_' + incident.priority | translate }}</span>
            </div>
            <div class="incidents__details--location">
            <span class="incidents__details--location-text">{{ incident.physicalLocation.name | translate }}</span>
            </div>
        </div>
    </div>
</div>