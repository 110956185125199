import { Injectable } from '@angular/core';

import { Language } from '@shared/enums/language.enum';
import { UserType } from '@shared/enums/user-type.enum';
import { StorageService } from '@core/storage/storage.service';
import { Preferences } from '@shared/models/preferences.model';

@Injectable()
export class PreferencesService {
  constructor(
    private storageService: StorageService
  ) { }

  getLanguage() {
    const preferences = this.storageService.getPreferences();
    if (preferences.locale) {
      switch (preferences.locale.language) {
        case Language.ENGLISH:
          return 'en';
        case Language.SPANISH:
          return 'es';
        case Language.BRAZILIAN_PORTUGUESE:
        default:
          return 'pt-br';
      }
    }
  }

  getPhoneMask() {
    const preferences = this.storageService.getPreferences();
    if (preferences && preferences.locale && preferences.locale.phoneMask) {
      return preferences.locale.phoneMask;
    }
  }

  isAdministrator(): boolean {
    let preferences = this.storageService.getPreferences();
    if (!preferences) {
      preferences = new Preferences();
    }
    return preferences.userType !== UserType.USER;
  }
}
