import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { RedirectService } from '@core/redirect/redirect.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { UserData } from '@shared/models/user-data.model';
import { StorageService } from '@core/storage/storage.service';

@Injectable()
export class G7Service {
  public token;

  constructor(
    private cookieService: CookieService,
    private redirectService: RedirectService,
    private httpCliente: HttpClient,
    private storageService: StorageService
  ) {
    this.token = this.getG7Token();
    if (!this.isAuthenticated()) {
      this.redirectService.toLogin();
    }
  }

  public getG7Cookie(name: string) {
    let userData = this.cookieService.get(name) || '{}';
    userData = JSON.parse(decodeURIComponent(userData.replace(/\+/g, ' ')));
    return typeof userData === 'object' ? userData : JSON.parse(userData);
  }

  public getG7Token() {
    return this.getG7Cookie('com.senior.token');
  }

  public async getG7UserData() {
    if (!this.storageService.getUserData()) {
      const userData = await this.httpCliente.get<UserData>(`${environment.urlSdl}/platform/user/queries/getUser`).toPromise();
      this.storageService.setUserData(userData);
    }
    return this.storageService.getUserData();
  }

  public isAuthenticated(): boolean {
    if (this.token) {
      return Object.keys(this.token).length !== 0;
    }
    return false;
  }

  public getUserName(): string {
    return this.token.username.split('@')[0];
  }

  public getToken(): string {
    if (this.isAuthenticated()) {
      return `Bearer ${this.token.access_token}`;
    }
    return null;
  }
}
