import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'documentMasking'
})
export class MaskingPipe implements PipeTransform {
  getMaskTypesRegex() {
    const validMaskTypes = ['9', 'Z', 'z', '#'];
    return new RegExp('[' + validMaskTypes.join('') + ']', 'g');
  }

  public applyMask(document: string, mask: string): string {
    if (!mask) {
      return document;
    }
    let formatedString = '';
    const last = -1;
    const separators = new RegExp('[./()-]', 'g');
    const maskRegex = this.getMaskTypesRegex();
    let count = 0;
    for (let i = 0; i < mask.length; i++) {
      if (mask[i].match(maskRegex)) {
        if (document[count]) {
          formatedString += document[count];
          count++;
        }
      } else {
        formatedString += mask[i];
      }
    }
    let lastCharacter = formatedString.slice(last);
    while (lastCharacter.match(separators)) {
      formatedString = formatedString.slice(0, last);
      lastCharacter = formatedString.slice(last);
    }
    return formatedString;
  }

  transform(string: string, mask: string): string {
    return this.applyMask(string, mask);
  }
}
