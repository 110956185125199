import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@environment/environment';
import { PermissionRoutine } from '@shared/models/permission-routine.model';
import { RoutineOperations } from '@shared/models/routine-operations.model';
import { PreferencesService } from '@core/preferences/preferences.service';
import { StorageService } from '@core/storage/storage.service';
import { Routines } from '@shared/enums/routines.enum';
import { RoutineOperation } from '@shared/enums/routine-operation.enum';

@Injectable()
export class PermissionsService {
  private endpoint = {
    permissionPersonSelect: `${environment.urlBackend}/permission/person/{personId}/select`,
    permissionPersonRoutine: `${environment.urlBackend}/permission/person/{personId}/routine/{routineId}`
  };

  constructor(
    private http: HttpClient,
    private preferencesService: PreferencesService,
    private storageService: StorageService
  ) { }

  getUserPermissions(): PermissionRoutine[] {
    return this.storageService.getUserPermissions();
  }

  getPermissionsRoutine(routine: number): Observable<RoutineOperations> {
    const preferences = this.storageService.getPreferences();
    if (preferences && preferences.personId) {
      let endpoint = this.endpoint.permissionPersonRoutine.replace(
        '{personId}',
        preferences.personId.toString()
      );
      endpoint = endpoint.replace('{routineId}', routine.toString());
      return this.http.get<RoutineOperations>(endpoint);
    }


  }

  isPermissionValid(routine): boolean {
    return this.preferencesService.isAdministrator()
      ? true
      : !!this.getUserPermissions().find(permission => permission.id === routine);
  }

  isRoutineReadOperation(routine: RoutineOperations): boolean {
    if (this.preferencesService.isAdministrator()) {
      return true;
    }
    for (const operation of routine.operation) {
      if (operation === RoutineOperation.READ) {
        return true;
      }
    }
    return false;
  }

  hasPermission(routine: Routines, operation: RoutineOperation): Observable<boolean> {
    return this.getPermissionsRoutine(routine).pipe(
      map(routineOperation => {
        const operations = routineOperation.operation || [];
        return operations.some(op => op === operation) || this.preferencesService.isAdministrator();
      }));
  }
}
