import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { throwError } from 'rxjs';
import * as moment from 'moment';

import { environment } from '@environment/environment';
import { G7Service } from '@core/g7/g7.service';
import { StorageService } from '@core/storage/storage.service';

declare const mixpanel: any;

@Injectable()
export class MixPanelService {
  userInformation;
  fullname;
  licenceUser = {};

  constructor(
    private g7Service: G7Service,
    private storageService: StorageService,
    private cookieService: CookieService
  ) { }

  private isMixPanelDeclared() {
    return typeof mixpanel !== 'undefined';
  }

  public async init() {
    if (this.isMixPanelDeclared()) {
      mixpanel.init(environment.mixpanelToken);
      this.userInformation = await this.g7Service.getG7UserData();
      if (this.userInformation.fullName) {
        this.fullname = this.userInformation.fullName.replace('+', ' ');
        this.setUserData();
      }
    }
  }

  setUserData() {
    const tenant = this.storageService.getTenant();
    const personId = this.cookieService.get('personId');
    mixpanel.identify(this.userInformation.email);
    mixpanel.people.set({
      $first_name: this.fullname,
      $email: this.userInformation.email,
      personId: personId,
      idG7: this.userInformation.id,
      licence: tenant.licenceNumberIncidentsUsers,
      tenantDomain: tenant.tenantDomain
    });
  }

  track(eventName) {
    if (this.isMixPanelDeclared()) {
      try {
        mixpanel.track(eventName, {
          date: moment().format()
        });
      } catch (e) {
        throwError(e);
      }
    }
  }
}
