import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {

  constructor(private injector: Injector) { }

  public get router(): Router {
    return this.injector.get(Router);
  }

  public sendAnalyticsTiming(page?: string) {
    if (!(window as any).HAS_LOADED && page) {
      (window as any).HAS_LOADED = true;
      const timeSincePageLoad = Math.round(performance.now());
      this.sendToAnalytics('tempo_carregamento_tela', {
        time_value: (timeSincePageLoad / 1000),
        page: page
      }, 'Monitor').then().catch((err) => {
        throw Error('Error in send analytics timing: ' + err);
      });
    }
  }

  private getBaseURL() {
    try {
      if (!document.cookie) {
        return Object;
      }
      const cookie = document.cookie.split(';').filter(cookieStr => {
        return cookieStr.includes('com.senior.base.url');
      });
      return decodeURIComponent(
        cookie[0].split('=')[1]
      ).slice(0, -1);
    } catch (err) {
      throw Error('Platform G7 not configured in this app: ' + err);
    }
  }

  private getActiveUser() {
    try {
      if (!document.cookie) {
        return Object;
      }
      const cookie = document.cookie.split(';').filter(cookieStr => {
        return cookieStr.includes('com.senior.token');
      });
      return JSON.parse(decodeURIComponent(
        cookie[0].split('=')[1]
      ));
    } catch (error) {
      throw Error('Error in Get Active User');
    }

  }

  public async sendToAnalytics(eventName: string, params: any, product: string) {
    const user = this.getActiveUser();
    gtag('event', eventName, {
      ...params,
      environment: this.getBaseURL(),
      user: user.username,
      tentant: user.tenantName,
      product: product ? product : params.product,
      debug_mode: true
    });
  }

  public getUrlWithoutIds(urlParts) {
    return urlParts.map(urlPart => {
      let url = urlPart;
      try {
        const id = parseFloat(urlPart) as any;
        if (id > 0 || id === 'new') {
          url = '{id}';
        }
      } catch (_) {
        url = urlPart;
      }
      return url;
    }).join('/');
  }

  init() {
    try {
      this.router.events.subscribe((event: NavigationStart) => {
        if (event instanceof NavigationEnd) {
          const url = this.getUrlWithoutIds(
            event.urlAfterRedirects.split('/')
          );
          this.sendToAnalytics(
            'page_view',
            {
              page_location: url,
              page_title: url,
              page_referrer: url,
              timestamp: new Date().getTime()
            },
            'Monitor'
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
}
