import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

import { DateTimeFormatService } from '@core/date-time-format/date-time-format.service';

@Pipe({ name: 'dateFromNow' })
export class DateFromNowPipe implements PipeTransform {
  transform(date: string) {
    return moment(date).fromNow();
  }
}

@Pipe({ name: 'dateFromNowRelative' })
export class DateFromNowRelativePipe implements PipeTransform {
  constructor(private dateTimeFormatService: DateTimeFormatService) { }

  transform(date: string) {
    if (moment().diff(moment(date), 'days') > 1) {
      const datePipe = new DatePipe('en-US');
      date = datePipe.transform(
        date,
        this.dateTimeFormatService.getDateTimeFormat()
      );
      return date;
    } else {
      return moment(date).fromNow();
    }
  }
}
