<section class="access-call-messages">
  <div #accessCallMessages class="acess-call-messages__messages padding-2">
    <div *ngFor="let msg of messages" class="acess-call-messages__speech-bubble font-size-1 padding-1" [ngClass]="{'speech-bubble--error': msg.status === 'error'}">
      {{msg.content}}
      <div class="flex-space-between speech-bubble__error-footer">
        <span class="text-input__obss">
          {{'ACCESS_CALL_MESSAGE_NOT_SENT' | translate}}
        </span>
        <span class="speech-bubble__resend-btn" (click)="sendMsg(msg.content)">
          <i class="fa fa-refresh"></i>
        </span>
      </div>
    </div>
  </div>
  <div class="access-call-messages__text-input">
    <div class="padding-2">
      <textarea placeholder="{{'ACCESS_CALL_MESSAGE_PLACEHOLDER' | translate}}" maxlength="128" (keyup)="updateRemainingChars()" class="text-input__text font-size-1" [(ngModel)]="msgContent"></textarea>
      <div class="flex-space-between">
        <span class="text-input__obs">{{messageCharsLeft}}</span>
        <button class="app-btn text-input__send" (click)="sendMsg()">{{'ACCESS_CALL_MESSAGE_SEND' | translate}}</button>
      </div>
    </div>
  </div>
</section>