import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';

@Injectable()
export class ServerService {
  private endpoints = {
    serverDateTime: `${environment.urlBackend}/server/datetime`
  };

  constructor(private http: HttpClient) { }

  getDateTime(): Observable<any> {
    return this.http.get<any>(this.endpoints.serverDateTime);
  }
}
