import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  forkJoin as observableForkJoin,
  throwError as observableThrowError,
  Observable
} from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from '@environment/environment';
import { DateTimeFormatService } from '@core/date-time-format/date-time-format.service';
import { TranslationService } from '@core/translate/translation.service';
import { Preferences } from '@shared/models/preferences.model';
import { PermissionRoutine } from '@shared/models/permission-routine.model';
import { Tenant } from '@shared/models/tenant.model';
import { StorageService } from '@core/storage/storage.service';
import { MixPanelService } from '@core/mix-panel/mix-panel.service';
import { Page } from '@shared/models/page.model';
import { UserSdl } from '@shared/models/user-sdl.model';

export function appInitializerFactory(injector: Injector) {
  const endpoints = {
    preferences: '/user/preferences',
    permissionPersonSelect: `${environment.urlBackend}/permission/person/{id}/select`,
    tenant: `${environment.urlBackend}/tenant`,
    user: `${environment.urlSdl}/platform/user/queries/getUser`,
  };
  const samApplicationUrl = `sam/application/entities`;
  const sdlEndpoints = {
    documentType: 'documentType'
  };

  return () =>
    new Promise<any>((resolve: any) => {
      const http: HttpClient = injector.get(HttpClient);
      const storageService: StorageService = injector.get(StorageService);
      const translationService: TranslationService = injector.get(
        TranslationService
      );
      const dateTimeFormatService: DateTimeFormatService = injector.get(
        DateTimeFormatService
      );
      const mixPanelService: MixPanelService = injector.get(MixPanelService);

      getUser().subscribe((user: UserSdl) => {
        translationService.defineLanguage(user);
      });

      fetchAndStorePreferences()
        .pipe(catchError((err) => {
          resolve();
          return observableThrowError(err);
        }))
        .subscribe((preferences: Preferences) => {
          dateTimeFormatService.defineDateTimeFormat(preferences);
          storageService.setUserData(null);
          observableForkJoin(
            fetchAndStorePermissions(preferences.personId),
            fetchAndStoreDocumentTypes(),
            fetchAndStoreTenant()
          )
            .pipe(catchError((err) => {
              resolve();
              return observableThrowError(err);
            }))
            .subscribe(async () => {
              await mixPanelService.init();
              resolve();
            });
        });

      function getUser(): Observable<UserSdl> {
        return http.get<any>(`${endpoints.user}`).pipe(catchError(() => resolve()));
      }

      function fetchAndStorePreferences(): Observable<Preferences> {
        return http
          .get<Preferences>(`${environment.urlBackend}${endpoints.preferences}`).pipe(
            tap(prefs => storageService.setPreferences(prefs)));
      }

      function fetchAndStorePermissions(
        id: number
      ): Observable<PermissionRoutine[]> {
        const endpoint = endpoints.permissionPersonSelect.replace(
          '{id}',
          id.toString()
        );
        return http
          .get<PermissionRoutine[]>(endpoint).pipe(
            tap(permissions => storageService.setPermissions(permissions)));
      }

      function fetchAndStoreTenant(): Observable<Tenant> {
        return http
          .get<Tenant>(endpoints.tenant).pipe(
            tap(tenant => storageService.setTenant(tenant)));
      }

      function fetchAndStoreDocumentTypes(): Observable<DocumentType[]> {
        return http.get<Page<DocumentType>>(`${environment.urlSdl}/${samApplicationUrl}/${sdlEndpoints.documentType}?size=100`).pipe(
          map(response => response.contents),
          tap(documentTypes => storageService.setAllDocumentTypes(documentTypes)), );
      }
    });
}
