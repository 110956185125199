import { Injectable } from '@angular/core';

import { Preferences } from '@shared/models/preferences.model';
import { DateFormat } from '@shared/enums/date-format.enum';
import { TimeFormat } from '@shared/enums/time-format.enum';

@Injectable()
export class DateTimeFormatService {
  private dateFormat;
  private timeFormat;
  constructor() { }

  public defineDateTimeFormat(preferences: Preferences) {
    if (preferences) {
      this.timeFormat = TimeFormat[preferences.locale.hourType];
      this.dateFormat = DateFormat[preferences.locale.dateType];
    } else {
      const defaultDateTime = 0;
      this.timeFormat = TimeFormat[defaultDateTime];
      this.dateFormat = DateFormat[defaultDateTime];
    }
  }

  getDateFormat() {
    return this.dateFormat;
  }

  getTimeFormat() {
    return this.timeFormat;
  }

  getDateTimeFormat() {
    return this.dateFormat + ' ' + this.timeFormat;
  }
}
