import {
  Component,
  ViewEncapsulation,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessCall } from '@shared/models/access-call.model';
import { AccessCallService } from '@core/access-call/access-call.service';
import { AccessCallStatus } from '@shared/models/access-call-status.model';
import { MessageService } from 'primeng/components/common/messageservice';
import { TranslateService } from '@ngx-translate/core';
import { Situation } from '@shared/enums/situation.enum';
import { AccessCallOrigin } from '@shared/enums/access-call-origin.enum';

@Component({
  selector: 'app-access-call-details',
  templateUrl: './access-call-details.component.html',
  styleUrls: ['./access-call-details.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AccessCallDetailsComponent implements OnInit, OnDestroy {
  @Input() public accessCall: AccessCall;
  @Input() drawerOpenedEvent;
  private _isLoading = false;
  @Output() isLoadingChange = new EventEmitter<boolean>();
  @Input()
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
    this.isLoadingChange.emit(this._isLoading);
  }
  @Output() public accessAllowed = new EventEmitter<any>();
  @Output() public accessDenied = new EventEmitter<any>();
  @ViewChild('drawerPersonPhoto', { static: false }) public personPhotoElRef: ElementRef;
  public personAccessValidationReason: string;
  public originDevice: string;

  private ngUnsubscribe = new Subject();

  constructor(
    public rootElRef: ElementRef,
    public accessCallService: AccessCallService,
    public messageService: MessageService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
    if (this.drawerOpenedEvent) {
      this.drawerOpenedEvent
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.updateTranslateStrings();
          this.updatePersonPhoto();
        });
    }

    if (this.accessCall) {
      this.updateTranslateStrings();
      this.updatePersonPhoto();
    }
  }

  ngOnDestroy() {
    if (this.ngUnsubscribe) {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }
  }

  public allowAccess() {
    this.messageService.clear();
    if (this.accessCall.movementType) {
      this.accessCallService.processMovement(this.accessCall);
      this.accessAllowed.emit();
    } else {
      this.allowAccessWithoutMovement();
    }
  }

  public denyAccess() {
    this.messageService.clear();
    this.isLoading = true;
    this.accessCallService
      .changeStatus(this.accessCall.id, AccessCallStatus.FINISHED_ACCESS_DENIED)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          const msg = this.translateService.instant(
            'ACCESS_CALL_ACCESS_DENIED'
          );
          this.messageService.add({ severity: 'success', detail: msg });
          this.accessCallService.onAccessCallDenied().emit(this.accessCall);
        },
        () => {
          this.isLoading = false;
          const msg = this.translateService.instant(
            'ACCESS_CALL_ACCESS_DENIED_ERROR'
          );
          this.messageService.add({ severity: 'error', detail: msg });
        },
        () => {
          this.isLoading = false;
          this.accessDenied.emit();
        }
      );
  }

  private updatePersonPhoto() {
    // o setTimeout é necessário devido ao controle feito com ngIf e o booleano containerShowing.
    // apenas no nextTick do setTimeout o ngIf estará true e o elemento de foto instanciado
    setTimeout(() => {
      if (
        this.personPhotoElRef &&
        this.accessCall &&
        this.accessCall.personPhotoUrl
      ) {
        this.personPhotoElRef.nativeElement.style.backgroundImage = `url(${
          this.accessCall.personPhotoUrl
          })`;
      }
    });
  }

  public hasPerson() {
    return (
      this.accessCall &&
      this.accessCall.person &&
      !this.accessCall.person.isNull()
    );
  }

  public isBlockedPerson() {
    return (
      this.hasPerson() &&
      this.accessCall.personSituation &&
      this.accessCall.personSituation.situation === Situation.BLOCKED
    );
  }

  public hasActiveRoles() {
    return (
      this.accessCall &&
      this.accessCall.personActiveRoles &&
      this.accessCall.personActiveRoles.length
    );
  }

  private allowAccessWithoutMovement() {
    this.isLoading = true;
    this.accessCallService
      .changeStatus(
        this.accessCall.id,
        AccessCallStatus.FINISHED_ACCESS_ALLOWED
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant(
              'ACCESS_CALL_ACCESS_GRANTED'
            )
          });
          this.accessCallService.onAccessCallAllowed().emit(this.accessCall);
        },
        () => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant(
              'ACCESS_CALL_ACCESS_GRANTED_ERROR'
            )
          });
        },
        () => {
          this.isLoading = false;
          this.accessDenied.emit();
        }
      );
  }

  private updateTranslateStrings() {
    if (this.accessCall && this.accessCall.accessValidation) {
      this.translateService
        .get(this.accessCall.accessValidation.reason)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(reason => (this.personAccessValidationReason = reason));
    }

    forkJoin(
      this.translateService.get('ACCESS_CALL_ORIGIN_DEVICE_INPUT'),
      this.translateService.get('ACCESS_CALL_ORIGIN_DEVICE_READER'),
      this.translateService.get('ACCESS_CALL_ORIGIN_SELFSERVICE_TERMINAL')
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        ([originDeviceInput, originDeviceReader, originSelfserviceTerminal]) => {
          if (this.accessCall.origin === AccessCallOrigin.DEVICE_INPUT) {
            this.originDevice = originDeviceInput;
          } else if (this.accessCall.origin === AccessCallOrigin.DEVICE_READER) {
            this.originDevice = originDeviceReader;
          } else if (
            this.accessCall.origin === AccessCallOrigin.SELFSERVICE_TERMINAL
          ) {
            this.originDevice = originSelfserviceTerminal;
          }
        }
      );
  }
}
