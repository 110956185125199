import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { RedirectService } from '../redirect/redirect.service';

@Injectable()
export class HttpErrorHandler {
  constructor(
    private cookieService: CookieService,
    private redirectService: RedirectService
  ) { }

  handle({ status }): void {
    switch (status) {
      case 401:
        this.cookieService.deleteAll('/', '.senior.com.br');
        this.redirectService.toLogin();
        break;
      case 403:
        this.redirectService.toForbidden();
        break;
    }
  }
}
