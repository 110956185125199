import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { Camera } from '@shared/models/camera.model';
import { Page } from '@shared/models/page.model';
import { CamerasInDevice } from '@shared/models/cameras-in-device.model';

@Injectable()
export class CameraService {
  private endpoints = {
    physicalLocationCameras: id => `${environment.urlBackend
      }/monitoring/physicallocation/${id}/camera`,
    virtualLobbyCameras: id => `${environment.urlBackend}/virtuallobby/${id}/cameras`
  };

  private samMonitorEntitiesUrl = 'sam/monitor/entities';
  private samMonitorQueriesUrl = 'sam/monitor/queries';
  private sdlEndpoints = {
    camera: 'camera',
    getCamerasFromDevice: id => `getCamerasFromDevice?deviceId=${id}`
  };

  constructor(private http: HttpClient) { }

  getCamerasInPhysicalLocation(id: number): Observable<Camera[]> {
    return this.http.get<Camera[]>(this.endpoints.physicalLocationCameras(id));
  }

  getCamerasInVirtualLobby(id: number): Observable<Camera[]> {
    return this.http.get<Camera[]>(this.endpoints.virtualLobbyCameras(id));
  }

  getCameraById(id: number): Observable<Camera> {
    return this.http.get<Camera>(`${environment.urlSdl}/${this.samMonitorEntitiesUrl}/${this.sdlEndpoints.camera}/${id}`);
  }

  getCamerasListByPhysicalLocation(id: number): Observable<Page<Camera>> {
    return this.http.get<Page<Camera>>(`${environment.urlSdl
      }/${this.samMonitorEntitiesUrl}/${this.sdlEndpoints.camera}?size=100&filter=physicalLocation.id eq ${id} and status eq ACTIVE`);
  }

  getCamerasInDevice(deviceId: number): Observable<CamerasInDevice> {
    return this.http.get<CamerasInDevice>(`${environment.urlSdl}/${this.samMonitorQueriesUrl}/${this.sdlEndpoints.getCamerasFromDevice(deviceId)}`);
  }
}
