import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environment/environment';
import { Access } from '@shared/models/access.model';
import { MonitoredNotification } from '@shared/models/monitored-notification.model';
import { WebSocketService } from '@core/web-socket/web-socket.service';
import { TopicChannel } from '@shared/enums/topic-channel.enum';
import { AccessEventsFilter } from '@events-monitoring/core/models/access-events-filter.model';
import { AccessEvents } from '@events-monitoring/core/models/access-events';
import { Vehicle } from '@shared/models/vehicle.model';

@Injectable()
export class MonitoringService {
  private endpoints = {
    pendingIncidents: `${environment.urlBackend}/monitoring/physicallocation/{id}/incident`,
    access: `${environment.urlBackend}/monitoring/access`,
    monitoredNotification: `${environment.urlBackend}/monitoring/physicallocation/{id}/notification`,
    accessEvents: `${environment.urlSdl}/sam/monitor/queries/listRealTimeClockIn`,
    accessVehicle: `${environment.urlSdl}/sam/monitor/queries/listRealTimeVehicleAccess`
  };

  constructor(private http: HttpClient, private webSocketService: WebSocketService) { }

  public getPendingIncidents(id: number = 0): Observable<number[]> {
    const url = this.endpoints.pendingIncidents.replace('{id}', id.toString());
    return this.http.get(url).pipe(map(r => <number[]>(<any>r).physicalLocation));
  }

  public getLastAccesses(id: number): Observable<Access[]> {
    const url = this.endpoints.access;
    const params = {
      physicalLocationId: [id],
      readerDeviceId: [],
      personId: [],
      roleId: [],
      groupId: [],
      accessCount: 50,
      accessTypeFilterGroup: 'ALL'
    };
    return this.http.post<Access[]>(url, params);
  }

  public startMonitoringAccess(): Observable<any> {
    return this.webSocketService.observeTopic(TopicChannel.ACCESS, 'monitoring');
  }

  public onCloseAccess(): void {
    this.webSocketService.onClose(TopicChannel.ACCESS, 'monitoring');
  }

  public startMonitoringClockIn() {
    return this.webSocketService.observeTopic(TopicChannel.CLOCK_IN, 'monitoring');
  }

  public onCloseAccessClockIn(): void {
    this.webSocketService.onClose(TopicChannel.CLOCK_IN, 'monitoring');
  }

  public getAllAccesses(params: any): Observable<Access[]> {
    const url = this.endpoints.access;
    return this.http.post<Access[]>(url, params);
  }

  public getMonitoredNotification(id: number): Observable<MonitoredNotification[]> {
    const url = this.endpoints.monitoredNotification.replace(
      '{id}',
      id.toString()
    );
    return this.http.get<MonitoredNotification[]>(url, {
      params: new HttpParams().set('limit', '100')
    });
  }

  public getAccessEvents(filters: AccessEventsFilter): Observable<AccessEvents> {
    return this.http.post<AccessEvents>(this.endpoints.accessEvents, filters);
  }

  public startMonitoringAccessVehicle(): Observable<any> {
    return this.webSocketService.observeTopic(TopicChannel.VEHICLE, 'monitoring');
  }

  public onCloseAccessVehicle(): void {
    this.webSocketService.onClose(TopicChannel.VEHICLE, 'monitoring');
  }

  public getAllAccessVehicle(params: any): Observable<Vehicle[]> {
    return this.http.post<Vehicle[]>(this.endpoints.accessVehicle, params);
  }
}
