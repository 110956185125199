import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { Group } from '@shared/models/group.model';
import { Page } from '@shared/models/page.model';

@Injectable()
export class GroupService {
  private queriesGroup = 'sam/application/entities';
  private sdlEndpoints = {
    groups: `${environment.urlSdl}/${this.queriesGroup}/group?size=100`
  };

  constructor(private http: HttpClient) { }

  public getGroups(search?: string): Observable<Page<Group>> {
    return this.http.get<Page<Group>>(search ?
      `${this.sdlEndpoints.groups}&filter=containing(upper(name), upper('${search.replace(/"/g, '\\"')}'))` :
      this.sdlEndpoints.groups
    );
  }
}
