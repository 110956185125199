import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/components/common/messageservice';
import { filter, mergeMap, map, mapTo } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@environment/environment';
import { AccessCallStatus } from '@shared/models/access-call-status.model';
import { AccessCall } from '@shared/models/access-call.model';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsService } from '@core/permissions/permissions.service';
import { Routines } from '@shared/enums/routines.enum';
import { MovementTypeEnum } from '@shared/enums/movement-type.enum';
import { AccessCallOrigin } from '@shared/enums/access-call-origin.enum';
import { VirtualLobbyService } from '@core/virtual-lobby/virtual-lobby.service';
import { Checkout } from '@shared/models/checkout.model';
import { EntryMovement } from '@shared/models/entry-movement.model';
import { WebSocketService } from '@core/web-socket/web-socket.service';
import { TopicChannel } from '@shared/enums/topic-channel.enum';

@Injectable()
export class AccessCallService {
  private accessNotificationsEvent: Observable<AccessCall[]>;
  private started = false;
  private openAccessCallBanner = new EventEmitter<AccessCall>();
  private openAccessCallDetail = new EventEmitter<AccessCall>();
  private accessCallAllowed = new EventEmitter<AccessCall>();
  private accessCallDenied = new EventEmitter<AccessCall>();
  private accessCallInProgress = new EventEmitter<AccessCall>();
  private linkedAccessCall = new EventEmitter<AccessCall>();

  private endpoints = {
    changeStatus: accessCallId =>
      `${environment.urlBackend}/accesscall/status/${accessCallId}`,
    pendingAccessCalls: `${environment.urlBackend}/accesscall/pending`,
    searchAccessCalls: `${environment.urlBackend}/accesscall/search`,
    sendDisplayMsg: deviceId =>
      `${environment.urlBackend}/device/${deviceId}/command/displayMessage`
  };

  constructor(
    public http: HttpClient,
    public permissionsService: PermissionsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private virtualLobbyService: VirtualLobbyService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private webSocketService: WebSocketService
  ) { }

  public getPendingAccessCalls(): Observable<AccessCall[]> {
    return this.http
      .get(this.endpoints.pendingAccessCalls).pipe(
        map((accessCallsJson: any) =>
          accessCallsJson.map(accessCall => AccessCall.fromJson(accessCall))
        ));
  }

  public getInProgressAccessCalls(): Observable<AccessCall[]> {
    const params = new HttpParams().set(
      'status',
      AccessCallStatus.IN_PROGRESS.toString()
    );
    return this.http
      .get(this.endpoints.searchAccessCalls, { params }).pipe(
        map((accessCallsJson: any[]) =>
          accessCallsJson.map(accessCall => AccessCall.fromJson(accessCall))
        ));
  }

  public changeStatus(
    accessCallID: number,
    status: AccessCallStatus
  ): Observable<any> {
    return this.http.put(this.endpoints.changeStatus(accessCallID), {
      status: AccessCallStatus[status]
    });
  }

  public onAccessCalls(): Observable<AccessCall[]> {
    if (!this.started && this.hasVirtualLobbyPermission()) {
      this.started = true;
      this.startAccessCallsPolling();
    }
    return this.accessNotificationsEvent;
  }

  public showAccessCallDetails(call: AccessCall) {
    this.openAccessCallDetail.emit(call);
  }

  public showAccessCallBanner(call: AccessCall) {
    this.openAccessCallBanner.emit(call);
  }

  public onAccessCallDetailsOpen() {
    return this.openAccessCallDetail;
  }

  public onAccessCallBannerOpen() {
    return this.openAccessCallBanner;
  }

  public onAccessCallAllowed(): EventEmitter<AccessCall> {
    return this.accessCallAllowed;
  }

  public onAccessCallDenied(): EventEmitter<AccessCall> {
    return this.accessCallDenied;
  }

  public onAccessCallInProgress(): EventEmitter<AccessCall> {
    return this.accessCallInProgress;
  }

  public onLinkedToAcessCall(): EventEmitter<AccessCall> {
    return this.linkedAccessCall;
  }

  public processMovement(accessCall: AccessCall) {
    if (this.shouldRedirectToMovement(accessCall)) {
      this.redirectToLinkedMovement(accessCall);
    } else {
      // Quando se tem todas informações executa a movimentação cadastrada automaticamente.
      this.executeMovement(accessCall);
    }
  }

  public toMovementEntry(accessCall: AccessCall): EntryMovement {
    const entryMovement = EntryMovement.nullInstance();
    entryMovement.accessCallId = accessCall.id;
    entryMovement.visitor = accessCall.person;
    entryMovement.virtualLobbyId = accessCall.virtualLobby.id;
    return entryMovement;
  }

  public toMovementLeave(accessCall: AccessCall): Checkout {
    const checkOut: Checkout = Checkout.nullInstance();
    checkOut.accessCallId = accessCall.id;
    checkOut.visitor = accessCall.person;
    checkOut.virtualLobbyId = accessCall.virtualLobby.id;
    return checkOut;
  }

  public shouldRedirectToMovement(accessCall: AccessCall): boolean {
    const isMovementRegisterEntryWithoutRole =
      accessCall.movementType === MovementTypeEnum.REGISTER_ENTRY &&
      accessCall.personActiveRoles.length === 0;
    const isMovementNewCredential =
      accessCall.movementType === MovementTypeEnum.ISSUE_ACCESS_CREDENTIAL;
    const isOriginInput = accessCall.origin === AccessCallOrigin.DEVICE_INPUT;
    return (
      isOriginInput ||
      isMovementNewCredential ||
      isMovementRegisterEntryWithoutRole
    );
  }

  private redirectToLinkedMovement(accessCall: AccessCall) {
    let navigation: Promise<boolean>;
    switch (accessCall.movementType) {
      case MovementTypeEnum.ISSUE_ACCESS_CREDENTIAL:
        navigation = this.router.navigate(
          [
            'virtualLobby',
            accessCall.virtualLobby.id,
            'operation',
            'new-credential'
          ],
          { relativeTo: this.activatedRoute }
        );
        break;
      case MovementTypeEnum.REGISTER_ENTRY:
        navigation = this.router.navigate(
          [
            'virtualLobby',
            accessCall.virtualLobby.id,
            'operation',
            'register-entry'
          ],
          { relativeTo: this.activatedRoute }
        );
        break;
      case MovementTypeEnum.REGISTER_LEAVE:
        navigation = this.router.navigate(
          ['virtualLobby', accessCall.virtualLobby.id, 'operation', 'checkout'],
          { relativeTo: this.activatedRoute }
        );
        break;
      default:
        throw new Error('Tipo de movimentação não implementado');
    }
    navigation.then(() => {
      this.linkedAccessCall.emit(accessCall);
    });
  }

  private onMovementSuccess(accessCall: AccessCall) {
    this.onAccessCallAllowed().emit(accessCall);
    this.messageService.add({
      severity: 'success',
      detail: this.translateService.instant('ACCESS_CALL_ACCESS_GRANTED')
    });
  }

  private executeMovement(accessCall: AccessCall) {
    switch (accessCall.movementType) {
      case MovementTypeEnum.REGISTER_ENTRY:
        this.virtualLobbyService
          .insertMovement(this.toMovementEntry(accessCall))
          .subscribe(
            () => {
              this.onMovementSuccess(accessCall);
            },
            () => this.accessCallError());
        break;
      case MovementTypeEnum.REGISTER_LEAVE:
        this.virtualLobbyService
          .insertMovementOut(this.toMovementLeave(accessCall))
          .subscribe(
            () => {
              this.onMovementSuccess(accessCall);
            },
            () => this.accessCallError());
        break;
      default:
        throw new Error('Tipo de movimentação não implementado');
    }
  }

  private accessCallError() {
    this.messageService.add({
      severity: 'error',
      detail: this.translateService.instant(
        'ACCESS_CALL_ACCESS_GRANTED_ERROR'
      )
    });
  }

  private startAccessCallsPolling() {
    this.accessNotificationsEvent = this.webSocketService.observeTopic(TopicChannel.ACCESS_CALL, 'access-call')
      .pipe(mapTo(this.getPendingAccessCalls())).pipe(
        mergeMap(accessCalls => accessCalls),
        filter(a => !!a && !!a.length));
  }

  private hasVirtualLobbyPermission() {
    return this.permissionsService.isPermissionValid(Routines.VIRTUAL_LOBBY);
  }

  public sendDisplayMsg(
    deviceId: number,
    message: string,
    duration = 10000,
    mode = 'ENQUEUE'
  ): Observable<any> {
    return this.http.post(this.endpoints.sendDisplayMsg(deviceId), {
      message,
      duration,
      mode
    });
  }
}
