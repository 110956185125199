<mat-drawer-container *ngIf="containerShowing" class="access-call-drawer-container">
  <mat-drawer class="access-call-drawer" mode="side" position="end" [opened]="opened">
    <span (click)="closeDrawer()" class="access-call-drawer__close-btn">
      <i class="fa fa-times fa-lg"></i>
    </span>
    <div class="access-call-drawer__section">
      <h1 class="access-call-drawer__title">
        {{'ACCESS_CALL_LABEL' | translate}}
      </h1>
    </div>

    <!-- TODO RON-2116 -->
    <!--<app-details-message-selector *ngIf="deviceAcceptsMessage()" [(optionSelected)]="detailsOrMessagesOption"></app-details-message-selector>-->
    <app-access-call-details *ngIf="showDetails()" [accessCall]="accessCall" [(isLoading)]="isLoading" (accessAllowed)="onAccessAllowedOrDenied()"
      (accessDenied)="onAccessAllowedOrDenied()" [drawerOpenedEvent]="drawerOpened"></app-access-call-details>
    <app-access-call-messages *ngIf="showMessages()" [accessCall]="accessCall"></app-access-call-messages>
  </mat-drawer>
</mat-drawer-container>
