import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environment/environment';

@Injectable()
export class RedirectService {
  private loginServlet = '/auth/LoginServlet';
  private entranceUrl = `${window.location.origin}${window.location.pathname}`;

  constructor(private router: Router, private cookieService: CookieService) { }

  /* istanbul ignore next */
  public toLogin(): void {
    if (!environment.karmaTest) {
      this.cookieService.deleteAll('/', '.senior.com.br');
      window.top.location.assign(
        `${environment.urlG7}${this.loginServlet}?redirectTo=${this.entranceUrl}`
      );
    }
  }

  public toNotFound(): void {
    this.router.navigateByUrl('/pageNotFound');
  }

  public toForbidden(): void {
    this.router.navigateByUrl('/pageForbidden');
  }
}
