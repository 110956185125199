<app-access-call-banner (accessCallAnswer)="onAccessCallAnswer($event)"></app-access-call-banner>
<app-access-call-details-drawer></app-access-call-details-drawer>
<div class="app-container">
  <div class="app-column--right ui-g">
    <div class="app-routing ui-g">
      <div class="ui-g-12 ui-g-nopad app-loading" [ngClass]="{'loading': isLoadingApp | async}">
        <app-nav-bar></app-nav-bar>
        <router-outlet class="app-main-route"></router-outlet>
        <ngx-loading [show]="isLoadingApp | async"></ngx-loading>
      </div>
    </div>
  </div>
</div>
<p-growl></p-growl>
<div *ngIf="isWebSocketDisconected" class="websocket-disconnected--background">
  <div class="websocket-disconnected--message">{{'WEBSOCKET_DISCONNECTED' | translate}}
    <span (click)="refresh()" class="refresh-button">{{'UPDATE' | translate}}</span>
  </div>
</div>
