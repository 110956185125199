export enum AccessCallOrigin {
  /** Dispositivo de entrada */
  DEVICE_INPUT = 'DEVICE_INPUT',

  /** Leitora */
  DEVICE_READER = 'DEVICE_READER',

  /** Terminal de autoatendimento */
  SELFSERVICE_TERMINAL = 'SELFSERVICE_TERMINAL'
}
