import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { appInitializerFactory } from './app.initializer';
import { AppRoutingModule } from './app.routing.module';
import { TranslationService } from '@core/translate/translation.service';
import { CoreModule } from '@core/core.module';
import { DefaultHttpInterceptor } from '@core/interceptors/default-http.interceptor';
import { SharedModule } from '@shared/shared.module';
import { NgxSamHopscotchModule } from '@seniorbr/ngx-sam-hopscotch';
import { GoogleAnalyticsModule } from '@core/google-analytics/google-analytics.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    NgxSamHopscotchModule.forRoot(),
    GoogleAnalyticsModule.forRoot()
  ],
  providers: [
    CookieService,
    TranslationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultHttpInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [Injector],
      multi: true
    }
  ],
  exports: [SharedModule],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
