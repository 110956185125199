const { project } = require('../../package.json');
export const environment = {
  production: false,
  urlBackend: 'https://platform-homologx.senior.com.br/t/senior.com.br/sam/1.0',
  urlG7: 'https://platform-homologx.senior.com.br',
  urlSdl: 'https://platform-homologx.senior.com.br/t/senior.com.br/bridge/1.0/rest',
  incidentUrl: '/incidents/index.html',
  monitorUrl: '/monitor/index.html',
  samUrl: 'https://sam-homologx.senior.com.br',
  samPort: '',
  samReaderUrl: 'http://localhost:8076/rest',
  mixpanelToken: 'f4e3f76e6d5eff9a5bc424679ce1cb6c',
  project,
  karmaTest: false
};
