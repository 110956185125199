import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@environment/environment';
import { Person } from '@shared/models/person.model';
import { User } from '@shared/models/user.model';

@Injectable()
export class PersonService {
  private endpoints = {
    personId: '/person/{personId}',
    personSearch: '/person/search',
    person: '/person',
    personPhoto: '/person/{personId}/photo',
    personPagedSearchAll: '/person/pagedsearchall',
    searchPeople: `${environment.urlBackend}/person/search?term={term}&situation={situation}&onlyReceiveVisit={onlyReceiveVisit}`,
    personRoles: `${environment.urlBackend}/person/{personId}/roles`,
    personUser: `platform/user/queries/listUsers`,

  };

  constructor(private http: HttpClient) { }

  public searchPeople(search: string): Observable<Person[]> {
    if (search.length < 3) {
      return observableOf([]);
    }
    const all = 0;
    const onlyReceiveVisit = false;
    let endpoint = this.endpoints.searchPeople;
    endpoint = endpoint.replace('{term}', search);
    endpoint = endpoint.replace('{situation}', all.toString());
    endpoint = endpoint.replace(
      '{onlyReceiveVisit}',
      onlyReceiveVisit.toString()
    );
    return this.http.get<Person[]>(endpoint);
  }

  public getPersonById(id: number): Observable<Person> {
    let endpoint = environment.urlBackend + this.endpoints.personId;
    endpoint = endpoint.replace('{personId}', id.toString());
    return this.http
      .get(endpoint).pipe(
        map((r: any) => Object.assign(new Person(r.id, r.name), r)));
  }

  public getPersonPhotoByPersonId(id: number): Observable<any> {
    let endpoint = environment.urlBackend + this.endpoints.personPhoto;
    endpoint = endpoint.replace('{personId}', id.toString());
    return this.http.get(endpoint).pipe(map(r => <any>r));
  }

  public getPersonSearch(
    term: string,
    onlyReceiveVisit: boolean
  ): Observable<Person[]> {
    const active = 0;
    const endpoint = environment.urlBackend + this.endpoints.personSearch;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('term', term);
    queryParams = queryParams.append('situation', active.toString());
    queryParams = queryParams.append(
      'onlyReceiveVisit',
      onlyReceiveVisit.toString()
    );
    return this.http
      .get(endpoint, { params: queryParams }).pipe(
        map((arr: Array<any>) => {
          return arr.map(r => Object.assign(new Person(r.id, r.name), r));
        }));
  }

  public getPersonSearchAll(
    page: number,
    pageSize: number,
    onlyReceiveVisit: boolean
  ): Observable<Array<Person>> {
    const active = '1';
    const endpoint =
      environment.urlBackend + this.endpoints.personPagedSearchAll;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('page', page.toString());
    queryParams = queryParams.append('pageSize', pageSize.toString());
    queryParams = queryParams.append('situation', active);
    queryParams = queryParams.append(
      'onlyReceiveVisit',
      onlyReceiveVisit.toString()
    );
    return this.http
      .get(endpoint, { params: queryParams }).pipe(
        map(r => <Array<Person>>r));
  }

  public insert(person): Observable<any> {
    const endpoint = environment.urlBackend + this.endpoints.person;
    return this.http.post(endpoint, person).pipe(catchError(err => observableThrowError(err)));
  }

  public searchPersonByUser(user: string): Observable<User[]> {
    return this.http.get<User[]>(this.endpoints.personUser, { params: { searchTerm: user } });
  }
}
