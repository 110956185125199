import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environment/environment';
import { Tenant } from '@shared/models/tenant.model';
@Injectable()
export class TenantService {

  private endpoints = {
    tenant: `${environment.urlBackend}/tenant`
  };

  constructor(private httpClient: HttpClient) { }

  public getTenant(): Observable<Tenant> {
    return this.httpClient.get<Tenant>(this.endpoints.tenant);
  }
}
