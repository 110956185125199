import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@environment/environment';

@Injectable()
export class CredentialService {
  endpoint = {
    credentialProvisoryCard: '/credential/provisory/card'
  };

  constructor(private http: HttpClient) { }

  addProvisoryCredential(credential, hasOverlap: boolean): Observable<any> {
    let endpoint =
      environment.urlBackend + this.endpoint.credentialProvisoryCard;
    if (hasOverlap) {
      endpoint += '?checkedOverlap=' + hasOverlap;
    }
    return this.http
      .post(endpoint, credential).pipe(
        catchError(err => observableThrowError(err)));
  }
}
