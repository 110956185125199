import { Person } from '@shared/models/person.model';

export class Checkout {
  constructor(
    public virtualLobbyId: number,
    public visitor: Person,
    public accessCallId?: number
  ) { }

  static nullInstance() {
    return new Checkout(-1, Person.nullInstance());
  }
}
