import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environment/environment';

@Injectable()
export class VirtualLobbyService {
  private endpoints = {
    accessCallLog: '/accesscall/pagedsearch',
    virtualLobby: '/virtuallobby/withrange',
    virtualLobbyMovement: '/virtuallobby/movement',
    virtualLobbyMovementOut: '/virtuallobby/movement/out',
    virtualLobbyId: '/virtuallobby/{id}',
    virtualLobbyByTerm: '/virtuallobby/search?term={term}&onlyActive=true'
  };

  constructor(private http: HttpClient) { }

  public getAllVirtualLobbies(): Observable<any[]> {
    const endpoint = environment.urlBackend + this.endpoints.virtualLobby;
    return this.http.get(endpoint).pipe(map(r => <any[]>r));
  }

  public getVirtualLobbies(virtuallobbyId: string): Observable<any> {
    const endpoint = environment.urlBackend + this.endpoints.virtualLobbyId.replace('{id}', virtuallobbyId);
    return this.http.get(endpoint).pipe(map(r => <any>r));
  }

  public insertMovement(movement): Observable<any> {
    const endpoint = environment.urlBackend + this.endpoints.virtualLobbyMovement;
    return this.http.post(endpoint, movement);
  }

  public insertMovementOut(movement): Observable<any> {
    const endpoint = environment.urlBackend + this.endpoints.virtualLobbyMovementOut;
    return this.http.post(endpoint, movement);
  }

  public getAccessCallLog(params): Observable<any> {
    const endpoint = environment.urlBackend + this.endpoints.accessCallLog;
    return this.http.post(endpoint, params);
  }

  public searchVirtualLobbyByTerm(term: string): Observable<any> {
    let endpoint = environment.urlBackend + this.endpoints.virtualLobbyByTerm;
    endpoint = endpoint.replace('{term}', term);
    return this.http.get(endpoint).pipe(map(r => <any>r));
  }
}
