import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  HostListener,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { WebSocketService } from '@core/web-socket/web-socket.service';
import { StorageService } from '@core/storage/storage.service';
import { IncidentService } from '@core/incident/incident.service';
import { IncidentStatus } from '@shared/enums/incident-status.enum';
import { Incident } from '@shared/models/incident.model';
import { TopicChannel } from '@shared/enums/topic-channel.enum';

@Component({
  selector: 'navbar-incident-bell',
  templateUrl: './incident-bell.component.html',
  styleUrls: ['./incident-bell.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class IncidentBellComponent implements OnInit, OnDestroy {
  @Input() autorizationAudioNotification = false;
  @Output() callback = new EventEmitter();
  public notifications = [];
  public incidentsSubscription: Subscription;
  public showNotifications = false;
  public notificationsExpanded = false;
  private firstTimeAutorizationAudioNotification = true;
  private insideClick = false;
  private readonly timeout100 = 100;

  constructor(
    private readonly incidentService: IncidentService,
    private readonly router: Router,
    private readonly webSocketService: WebSocketService,
    private readonly storageService: StorageService
  ) { }

  public ngOnInit(): void {
    this.incidentsSubscription = this.startMonitoring();
  }

  public ngOnDestroy(): void {
    this.incidentService.incidentNotifications.unsubscribe();
    this.webSocketService.onClose(TopicChannel.INCIDENT, 'incident-bell');
  }

  @HostListener('click')
  @HostListener('touchstart')
  public clickInside(): void {
    this.insideClick = true;
  }

  @HostListener('document:click')
  @HostListener('document:touchstart')
  public clickout(): void {
    if (!this.insideClick) {
      this.closeNotifications();
    }
    this.insideClick = false;
  }

  @HostListener('document:mousemove', ['$event'])
  public onMouseMove(): void {
    if (this.firstTimeAutorizationAudioNotification) {
      this.audioAuthorization().then(resp => {
        if (resp && this.firstTimeAutorizationAudioNotification && !this.autorizationAudioNotification) {
          this.firstTimeAutorizationAudioNotification = false;
          this.callback.emit(true);
        }
      });
    }
  }

  public hasNotifications(): boolean {
    return this.notifications.length !== 0;
  }

  public hasCloseHeader(): boolean {
    return this.notificationsExpanded || !this.hasNotifications();
  }

  public hasExpandHeader(): boolean {
    return !this.notificationsExpanded && this.hasNotifications();
  }

  public getNotifications(): any {
    return this.notifications.filter(
      (_, index) => index < this.getNotificationLimit()
    );
  }

  public toggleNotifications(): void {
    if (this.showNotifications) {
      this.closeNotifications();
    } else {
      this.showNotifications = true;
      this.notificationsExpanded = false;
      this.addOverlay();
    }
  }

  public expandNotifications(): void {
    this.notificationsExpanded = true;
  }

  public closeNotifications(): void {
    this.showNotifications = false;
    this.notificationsExpanded = false;
    this.removeOverlay();
  }

  public onClickIncident(incident: any): void {
    this.router.navigate([
      'monitoring',
      incident.physicalLocation.id,
      'operation'
    ]);
    this.closeNotifications();
  }

  private getNotificationLimit(): number {
    const maxNotifications = 9;
    const minNotifications = 3;
    if (this.notificationsExpanded) {
      return maxNotifications;
    }
    return minNotifications;
  }

  private addOverlay(): void {
    const mainRoute = document.getElementsByClassName('app-main-route')[0];
    if (mainRoute) {
      mainRoute.classList.add('overlay');
    }
  }

  private removeOverlay(): void {
    const mainRoute = document.getElementsByClassName('app-main-route')[0];
    if (mainRoute) {
      mainRoute.classList.remove('overlay');
    }
  }

  private startMonitoring(): Subscription {
    return this.webSocketService.observeTopic(TopicChannel.INCIDENT, 'incident-bell').pipe(
      switchMap(() => this.incidentService.getNotifications()))
      .subscribe(incidents => {
        this.notifications = incidents;
        if (this.notifications.length > 0) {
          this.alertNotifications(this.notifications);
        }
      });
  }

  private alertNotifications(notifications: any): void {
    const notificationKey = [];
    notifications.forEach((notification: Incident) => {
      if (notification.incidentType.isWarningSound && notification.status !== IncidentStatus.DONE) {
        notificationKey.push(notification.incidentType.id);
      }
    });

    if (notificationKey.length > 0) {
      const localStorage = this.getAlertedNotification();
      if (localStorage !== notificationKey && this.autorizationAudioNotification) {
        this.playAudio();
      }
      this.storageService.setAlertedNotifications(notificationKey);
    }
  }

  private playAudio(): void {
    const audio = new Audio('./assets/audio/alert.mp3');
    audio.load();
    audio.play();
  }

  private audioAuthorization(): any {
    return new Promise((resolve) => {
      const audio = new Audio();
      audio.load();
      setTimeout(() => {
        resolve(true);
      }, this.timeout100);
      audio.play().catch(() => resolve(false));
    });
  }

  private getAlertedNotification(): any {
    return this.storageService.getAlertedNotifications();
  }
}
