import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { environment } from '@environment/environment';
import { DeviceType } from '@shared/enums/device-type.enum';
import { Device } from '@shared/models/device.model';
import { DeviceNode } from '@shared/models/deviceNode.model';
import { ManagerDeviceDetail } from '@shared/models/manager-device-detail.model';
import { ManagerDevice } from '@shared/models/manager-device.model';
import { InputDevice } from '@shared/models/input-device.model';
import { ModuleDevice } from '@shared/models/module.device.model';
import { DevicePath } from '@shared/models/device-path.model';
import { WebSocketService } from '@core/web-socket/web-socket.service';
import { TopicChannel } from '@shared/enums/topic-channel.enum';
import { PhysicalLocation } from '@shared/models/physical-location.model';

@Injectable()
export class DeviceService {
  public deviceNotifications: EventEmitter<DeviceNode[]> = new EventEmitter<DeviceNode[]>();

  private endpoints = {
    childrenPhysicalLocation: `${environment.urlBackend
      }/monitoring/physicallocation/{id}/device`,
    searchDevices: `${environment.urlBackend
      }/device/search?term={term}&status={status}`,
    managers: `${environment.urlBackend}/monitoring/manager/status`,
    managerDetail: `${environment.urlBackend}/monitoring/manager/{id}/status/detail`,
    devicesByType: `${environment.urlBackend}/physicallocation/{physicalLocationId}/device`,
    moduleById: `${environment.urlBackend}/device/module/{id}`,
    inputById: `${environment.urlBackend}/device/input/{id}`,
    devicePath: `${environment.urlBackend}/device/{id}/path`,
    handleTriggeredDevice: `${environment.urlSdl}/sam/application/actions/handleTriggeredDevice`
  };

  constructor(private http: HttpClient, private webSocketService: WebSocketService) { }

  public executeHandleTriggeredDevice(managerDeviceId) {
    return this.http.post<ManagerDeviceDetail[]>(this.endpoints.handleTriggeredDevice, managerDeviceId);
  }

  searchDevices(search: string): Observable<Device[]> {
    if (search.length < 3) {
      return;
    }
    const all = 0;
    let endpoint = this.endpoints.searchDevices.replace('{term}', search);
    endpoint = endpoint.replace('{status}', all.toLocaleString());
    return this.http.get<Device[]>(endpoint);
  }

  startMonitoring(): Subscription {
    this.getChildrenNode().subscribe(deviceTree =>
      this.deviceNotifications.emit(deviceTree)
    );

    return this.webSocketService.observeTopic(TopicChannel.DEVICE, 'device').pipe(
      switchMap(() => this.getChildrenNode()))
      .subscribe(deviceTree => this.deviceNotifications.emit(deviceTree));
  }

  getChildrenNode(id: number = 1): Observable<DeviceNode[]> {
    const url = this.endpoints.childrenPhysicalLocation.replace(
      '{id}',
      id.toString()
    );
    return this.http.get<DeviceNode[]>(url);
  }

  getManagers(physicallocation?: PhysicalLocation, deviceStatus?: string, deviceId?: string, networkIdentification?: string): Observable<ManagerDevice[]> {
    let params = new HttpParams();
    if (physicallocation) {
      params = params.set('physicalLocationFilter', physicallocation.id.toString());
    }

    if (deviceId) {
      params = params.set('deviceIdFilter', deviceId);
    }

    if (deviceStatus) {
      params = params.set('deviceStatusFilter', deviceStatus);
    }

    if (networkIdentification) {
        params = params.set('networkIdentificationFilter', networkIdentification);
    }
    return this.http.get<ManagerDevice[]>(this.endpoints.managers, { params });
  }

  getManagerDetail(id: number): Observable<ManagerDeviceDetail[]> {
    const url = this.endpoints.managerDetail.replace('{id}', id.toString());
    return this.http.get<ManagerDeviceDetail[]>(url);
  }

  getInputsByPhysicalLocation(id: number): Observable<Device[]> {
    const url = this.endpoints.devicesByType.replace('{physicalLocationId}', id.toString());
    let queryParams = new HttpParams();
    queryParams = queryParams.append('deviceType', DeviceType.INPUT);
    return this.http.get<Device[]>(url, { params: queryParams });
  }

  getManagersByPhysicalLocation(id: number): Observable<Device[]> {
    const url = this.endpoints.devicesByType.replace('{physicalLocationId}', id.toString());
    let queryParams = new HttpParams();
    queryParams = queryParams.append('deviceType', DeviceType.MANAGER);
    return this.http.get<Device[]>(url, { params: queryParams });
  }

  getModulesByPhysicalLocation(id: number): Observable<Device[]> {
    const url = this.endpoints.devicesByType.replace('{physicalLocationId}', id.toString());
    let queryParams = new HttpParams();
    queryParams = queryParams.append('deviceType', DeviceType.MODULE);
    return this.http.get<Device[]>(url, { params: queryParams });
  }

  getInputById(id: number): Observable<InputDevice> {
    const url = this.endpoints.inputById.replace('{id}', id.toString());
    return this.http.get<InputDevice>(url);
  }

  getModuleById(id: number): Observable<ModuleDevice> {
    const url = this.endpoints.moduleById.replace('{id}', id.toString());
    return this.http.get<ModuleDevice>(url);
  }

  getDevicePath(id: number): Observable<DevicePath[]> {
    const url = this.endpoints.devicePath.replace('{id}', id.toString());
    return this.http.get<DevicePath[]>(url);
  }
}
