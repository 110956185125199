import {
  Component,
  AfterViewInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

import { AccessCall } from '@shared/models/access-call.model';
import { AccessCallService } from '@core/access-call/access-call.service';
import { AccessCallOrigin } from '@shared/enums/access-call-origin.enum';

@Component({
  selector: 'app-access-call-banner',
  templateUrl: './access-call-banner.component.html',
  styleUrls: ['./access-call-banner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AccessCallBannerComponent
  implements OnChanges, AfterViewInit, OnInit {
  private static readonly TIMEOUT = 20000;
  @ViewChild('notificationBannerPersonPhoto', { static: false }) public personPhotoEl: ElementRef;
  @ViewChild('notificationBanner', { static: false }) public rootElement: ElementRef;
  @Output() public accessCallAnswer = new EventEmitter<any>();
  @Output() public accessCallTimeout = new EventEmitter<any>();
  private accessCall: AccessCall;
  public timedOut = false;
  private bannerTimeoutId;
  public minutesSinceTimeout = 0;
  public minutesSinceTimeoutMsg: string;
  private minutesSinceTimeoutCounterIntervalId;
  public isBannerShowing = false;

  constructor(
    public translateService: TranslateService,
    public accessCallService: AccessCallService,
    private router: Router
  ) { }

  ngOnInit() {
    this.accessCallService.onAccessCallBannerOpen().subscribe(call => {
      if (this.accessCall && this.accessCall.id === call.id) {
        return;
      }
      this.accessCall = call;
      this.updateBannerVisibility();
      this.updateBannerPhoto();
    });
  }

  ngAfterViewInit() {
    this.startTimeoutCount();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.accessCall.currentValue &&
        !changes.accessCall.currentValue.device) ||
      !changes.accessCall.currentValue.virtualLobby
    ) {
      throw new Error('As properties device e currentValue são obrigatorias');
    }
  }

  getOpenCallIcon() {
    if (!this.accessCall || !this.accessCall.origin) {
      return '';
    }
    if (this.accessCall.origin === AccessCallOrigin.DEVICE_INPUT) {
      return './assets/images/icon-78x116-generic-notification-white.png';
    }
    if (this.accessCall.origin === AccessCallOrigin.DEVICE_READER) {
      return './assets/images/icon-78x116-reader-notification-white.svg';
    }
    if (this.accessCall.origin === AccessCallOrigin.SELFSERVICE_TERMINAL) {
      return './assets/images/icon-78x116-kiosk-notification-white.svg';
    }
  }

  private updateBannerVisibility() {
    this.showBanner();
    this.startTimeoutCount();
    this.startMinutesSinceTimeoutCounter();
  }

  private updateBannerPhoto() {
    if (!this.accessCallHasPerson()) {
      return;
    }
    this.updatePersonPhoto(this.accessCall.personPhotoUrl);
  }

  public accessCallHasPerson() {
    return this.accessCall && !!this.accessCall.person;
  }

  public answerCall() {
    this.accessCallAnswer.emit(this.accessCall);
    this.accessCallService.onAccessCallInProgress().emit(this.accessCall);
    this.router.navigate(['virtualLobby', this.accessCall.virtualLobby.id, 'operation']);
    this.hideBanner();
  }

  private startTimeoutCount() {
    if (this.bannerTimeoutId) {
      clearTimeout(this.bannerTimeoutId);
      this.bannerTimeoutId = undefined;
    }
    this.timedOut = false;
    this.bannerTimeoutId = setTimeout(() => {
      this.accessCallTimeout.emit();
      this.timedOut = true;
    }, this.getRemainingTime());
  }

  private startMinutesSinceTimeoutCounter() {
    const oneMinuteInMillis = 60000;
    if (this.minutesSinceTimeoutCounterIntervalId) {
      clearInterval(this.minutesSinceTimeoutCounterIntervalId);
      this.minutesSinceTimeout = 0;
    }
    this.minutesSinceTimeoutMsg = this.translateService.instant(
      'ACCESS_CALL_TIMEOUT_MSG_LT_MIN'
    );
    this.minutesSinceTimeoutCounterIntervalId = setInterval(() => {
      this.minutesSinceTimeout++;
      if (this.minutesSinceTimeout === 1) {
        this.minutesSinceTimeoutMsg = this.translateService.instant(
          'ACCESS_CALL_TIMEOUT_MSG_EQ_MIN',
          { elapsedTimeout: this.minutesSinceTimeout }
        );
      } else {
        this.minutesSinceTimeoutMsg = this.translateService.instant(
          'ACCESS_CALL_TIMEOUT_MSG_GT_MIN',
          { elapsedTimeout: this.minutesSinceTimeout }
        );
      }
    }, oneMinuteInMillis);
  }

  private getRemainingTime() {
    if (!this.accessCall) {
      return 0;
    }
    const createdAt = moment(this.accessCall.createDate);
    const now = moment();
    const remaining =
      AccessCallBannerComponent.TIMEOUT -
      Math.abs(createdAt.diff(now, 'milliseconds'));
    return remaining > 0 ? remaining : AccessCallBannerComponent.TIMEOUT;
  }

  private updatePersonPhoto(personPhoto) {
    setTimeout(() => {
      if (!this.personPhotoEl) {
        return;
      }
      const personPhotoImgEl = this.personPhotoEl
        .nativeElement as HTMLImageElement;
      personPhotoImgEl.style.backgroundImage = `url('${personPhoto}')`;
    });
  }

  public hideBanner() {
    if (this.rootElement) {
      const thisEl = this.rootElement.nativeElement as HTMLElement;
      thisEl.classList.add('access-call--leaving');
    }
    this.isBannerShowing = false;
  }

  private showBanner() {
    this.isBannerShowing = true;
    if (this.rootElement) {
      const thisEl = this.rootElement.nativeElement as HTMLElement;
      thisEl.classList.remove('access-call--leaving');
    }
  }

}
