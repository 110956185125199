import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';

import { translateEn } from './i18n/en-US';
import { translateEs } from './i18n/es-ES';
import { translateBr } from './i18n/pt-BR';
import { UserSdl } from '@shared/models/user-sdl.model';
import { LanguageSdl } from '@shared/enums/language-sdl.enum';

@Injectable()
export class TranslationService {
  constructor(private translateService: TranslateService) { }

  public defineLanguage(user: UserSdl) {
    switch (user.locale) {
      case LanguageSdl.ENGLISH:
        moment.locale('en');
        this.translateService.setTranslation('en', translateEn);
        this.translateService.setDefaultLang('en');
        break;
      case LanguageSdl.SPANISH:
        moment.locale('es');
        this.translateService.setTranslation('es', translateEs);
        this.translateService.setDefaultLang('es');
        break;
      case LanguageSdl.BRAZILIAN_PORTUGUESE:
      default:
        moment.locale('pt-BR');
        this.translateService.setTranslation('pt-BR', translateBr);
        this.translateService.setDefaultLang('pt-BR');
    }
  }
}
