export enum Routines {
  PERSON = 1,
  REJECTED_EVENTS_SEARCH = 23,
  INCIDENT = 25,
  PRESENT_PEOPLE = 29,
  MONITORING = 33,
  VIRTUAL_LOBBY = 36,
  VIRTUAL_CREDENTIAL = 37,
  ACCESS_CALL_LOG = 39
}
