import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { GoogleAnalyticsService } from './google-analytics.service';

@NgModule()
export class GoogleAnalyticsModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: GoogleAnalyticsModule,
      providers: [
        GoogleAnalyticsService,
        {
          provide: APP_INITIALIZER,
          useFactory: factory,
          deps: [GoogleAnalyticsService],
          multi: true
        }
      ]
    };
  }
}

export function factory(googleAnalytics: GoogleAnalyticsService) {
  return () => {
    googleAnalytics.init();
  };
}
