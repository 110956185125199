import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { Incident } from '@shared/models/incident.model';

@Injectable()
export class IncidentService {
  public incidentNotifications: EventEmitter<Incident[]> = new EventEmitter<Incident[]>();

  private endpoints = {
    incidentNotification: `${environment.urlBackend}/incident/pagedsearch/notification`,
    physicalLocationIncidents: `${environment.urlBackend}/incident/search/physicallocation/{id}`
  };

  constructor(private http: HttpClient) { }

  getNotifications(): Observable<Incident[]> {
    return this.http.get<Incident[]>(this.endpoints.incidentNotification, {
      params: new HttpParams().set('page', '1').set('pageSize', '10')
    });
  }

  getPhysicalLocationIncidents(id: number): Observable<Incident[]> {
    const endpoint = this.endpoints.physicalLocationIncidents.replace('{id}', id.toString());
    return this.http.get<Incident[]>(endpoint);
  }
}
