import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SamDataCardComponent } from './sam-data-card.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SamDataCardComponent],
  providers: [],
  exports: [SamDataCardComponent]
})
export class SamDataCardModule {}
