export enum DeviceType {
  MANAGER = 'MANAGER',
  READER = 'READER',
  MODULE = 'MODULE',
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT'
}

export enum DeviceTypeNumber {
  MANAGER = 0,
  READER = 1,
  MODULE = 2,
  INPUT = 3,
  OUTPUT = 4
}
