import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environment/environment';
import { Role } from '@shared/models/role.model';

@Injectable()
export class RoleService {
  private endpoints = {
    roleSearch: `${environment.urlBackend}/role/search`,
    allProvisoryRoles: `${environment.urlBackend}/role/pagedsearch/all`
  };

  constructor(private http: HttpClient) { }

  public getAllProvisoryRoles(): Observable<Role[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('page', '1');
    queryParams = queryParams.append('pageSize', '100');
    queryParams = queryParams.append('provisory', 'true');
    return this.http
      .get<Role[]>(this.endpoints.allProvisoryRoles, {
        params: queryParams
      });
  }

  public searchRoles(term: string): Observable<Role[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('term', term);

    return this.http
      .get<Role[]>(this.endpoints.roleSearch, {
        params: queryParams
      });
  }
}
