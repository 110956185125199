import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { HttpErrorHandler } from '@core/http-error-handler/http-error-handler.service';

@Injectable()
export class DefaultHttpInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private httpErrorHandler: HttpErrorHandler
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: { token_type: string; access_token: string } = JSON.parse(
      this.cookieService.get('com.senior.token') || '{}'
    );
    const restAddress = `${this.cookieService.get(
      'com.senior.services.url'
    )}rest/`;
    const newReq = req.clone({
      url: req.url.includes('://') ? req.url : restAddress + req.url,
      headers: req.headers.set(
        'Authorization',
        `${token.token_type} ${token.access_token}`
      )
    });

    return next.handle(newReq).pipe(catchError((error: HttpErrorResponse) => {
      this.httpErrorHandler.handle(error);
      return observableThrowError(error);
    }));
  }
}
